import { getClientData } from "../DataModelEngine";

const companyName = getClientData('clientName');
const companyEmail = getClientData('clientEmail');

const userAgreement = {
    NO: {
        userAgreement: [
            {
                header: 'Introduksjon',
                content: [
                    'Velkommen til Jobbleder.no, driftet av ' + companyName + ' ("Selskapet", "vi", "oss", "vårt"). Ved å registrere deg og bruke vår plattform, godtar du å overholde disse vilkårene og betingelsene. Hvis du ikke godtar vilkårene, må du ikke bruke våre tjenester.',
                    ],
            },
            {
                header: 'Brukerregistrering og kontoforpliktelser',
                content: [
                    'For å få tilgang til våre tjenester, må du registrere en konto og oppgi nøyaktig og fullstendig informasjon.',
                    'Du er ansvarlig for å opprettholde konfidensialiteten til dine kontoopplysninger.',
                    'Du godtar å ikke dele dine innloggingsdetaljer med tredjeparter.',
                    'Hvis du mistenker uautorisert bruk av kontoen din, må du varsle oss umiddelbart.'
                ],
            },
            {
                header: 'Datainnsamling og personvern',
                content: [
                    'Vi samler inn og lagrer følgende brukerdata:',
                    [
                        'Navn',
                        'E-post',
                        'Yrke',
                        'Firmanavn',
                        'Firmaadresse',
                        'Firmaets bankkontonummer',
                        'Firmaets MVA-nummer',
                        'Fakturadetaljer',
                        'Timelistedetaljer',
                        'Tilbudsdokumentdetaljer'
                    ],
                    'Vi behandler disse dataene i samsvar med EUs personvernforordning (GDPR) og norsk personvernlovgivning.',
                    'For mer informasjon, vennligst se vår Personvernerklæring.'
                ],
            },
            {
                header: 'Betalingsvilkår',
                content: [
                    'Hvis våre tjenester innebærer betalinger, godtar du å oppgi korrekte faktureringsopplysninger.',
                    'Alle transaksjoner er endelige med mindre annet er oppgitt i vår refusjonspolicy.'
                ],
            },
            {
                header: 'Ansvarsbegrensning',
                content: [
                    'Vi streber etter å sikre nøyaktighet og pålitelighet i vår plattform, men garanterer ikke uavbrutt tjeneste.',
                    'Vi er ikke ansvarlige for direkte, indirekte eller konsekvensielle skader som oppstår ved bruk av vår plattform.'
                ],
            },
            {
                header: 'Oppsigelse av konto',
                content: [
                    'Brukere kan be om sletting av konto når som helst.',
                    'Vi forbeholder oss retten til å suspendere eller avslutte kontoer som bryter med disse vilkårene.'
                ],
            },
            {
                header: 'Overholdelse av norsk lov og GDPR',
                content: [
                    'Vi overholder norsk lov og GDPR når det gjelder behandling, lagring og rettigheter knyttet til brukerdata.',
                    'Brukere har rett til å be om innsyn, korrigering eller sletting av sine data.'
                ],
            },
            {
                header: 'Endringer i avtalen',
                content: [
                    'Vi forbeholder oss retten til å oppdatere denne avtalen når som helst.',
                    'Brukere vil bli varslet om vesentlige endringer før de trer i kraft.'
                ],
            },
            {
                header: 'Kontaktinformasjon',
                content: [
                    'Hvis du har spørsmål om denne avtalen, kan du kontakte oss på: ' + companyEmail + ' .'
                ],
            }
        ]
    },
    EN: {
        userAgreement: [
            {
                header: 'Introduction',
                content: [
                    'Welcome to [Your Company Name] ("Company", "we", "us", "our"). By registering and using our platform, you agree to comply with these terms and conditions ("Agreement"). If you do not agree to the terms, do not use our services.'
                ],
            },
            {
                header: 'User Registration and Account Obligations',
                content: [
                    'To access our services, you must register an account and provide accurate and complete information.',
                    'You are responsible for maintaining the confidentiality of your account credentials.',
                    'You agree not to share your login details with third parties.',
                    'If you suspect unauthorized use of your account, you must notify us immediately.'
                ],
            },
            {
                header: 'Data Collection and Privacy',
                content: [
                    'We collect and store the following user data:',
                    [
                        'Name',
                        'Email',
                        'Profession',
                        'Company Name',
                        'Company Address',
                        'Company Bank Account Number',
                        'Company VAT Number',
                        'Invoice Details',
                        'Timesheet Details',
                        'Budget/Offer Document Details'
                    ],
                    'We process this data in accordance with the EU General Data Protection Regulation (GDPR) and Norwegian privacy laws.',
                    'For more information, please refer to our Privacy Policy.'
                ],
            },
            {
                header: 'Payment Terms',
                content: [
                    'If our services involve payments, you agree to provide accurate billing information.',
                    'All transactions are final unless otherwise stated in our refund policy.'
                ],
            },
            {
                header: 'Limitation of Liability',
                content: [
                    'We strive to ensure accuracy and reliability on our platform but do not guarantee uninterrupted service.',
                    'We are not liable for direct, indirect, or consequential damages arising from the use of our platform.'
                ],
            },
            {
                header: 'Account Termination',
                content: [
                    'Users can request account deletion at any time.',
                    'We reserve the right to suspend or terminate accounts that violate these terms.'
                ],
            },
            {
                header: 'Compliance with Norwegian Law and GDPR',
                content: [
                    'We comply with Norwegian law and GDPR regarding the processing, storage, and rights related to user data.',
                    'Users have the right to request access, correction, or deletion of their data.'
                ],
            },
            {
                header: 'Changes to the Agreement',
                content: [
                    'We reserve the right to update this agreement at any time.',
                    'Users will be notified of significant changes before they take effect.'
                ],
            },
            {
                header: 'Contact Information',
                content: [
                    'If you have any questions about this agreement, you can contact us at: [Your contact email].'
                ],
            }
        ]
    },
    PL: {
        userAgreement: [
            {
                header: 'Wstęp',
                content: [
                    'Witamy w [Nazwa Twojej Firmy] ("Firma", "my", "nas", "nasz"). Rejestrując się i korzystając z naszej platformy, zgadzasz się na przestrzeganie niniejszych warunków ("Umowa"). Jeśli nie akceptujesz warunków, nie korzystaj z naszych usług.'
                ],
            },
            {
                header: 'Rejestracja użytkownika i zobowiązania dotyczące konta',
                content: [
                    'Aby uzyskać dostęp do naszych usług, musisz zarejestrować konto i podać dokładne oraz pełne informacje.',
                    'Jesteś odpowiedzialny za zachowanie poufności danych logowania do konta.',
                    'Zgadzasz się nie udostępniać swoich danych logowania osobom trzecim.',
                    'Jeśli podejrzewasz nieautoryzowane użycie swojego konta, musisz nas o tym niezwłocznie powiadomić.'
                ],
            },
            {
                header: 'Zbieranie danych i prywatność',
                content: [
                    'Zbieramy i przechowujemy następujące dane użytkownika:',
                    [
                        'Imię i nazwisko',
                        'E-mail',
                        'Zawód',
                        'Nazwa firmy',
                        'Adres firmy',
                        'Numer rachunku bankowego firmy',
                        'Numer VAT firmy',
                        'Szczegóły faktur',
                        'Szczegóły kart czasu pracy',
                        'Szczegóły budżetu/oferty'
                    ],
                    'Przetwarzamy te dane zgodnie z ogólnym rozporządzeniem o ochronie danych (RODO) oraz norweskimi przepisami o ochronie prywatności.',
                    'Aby uzyskać więcej informacji, zapoznaj się z naszą Polityką Prywatności.'
                ],
            },
            {
                header: 'Warunki płatności',
                content: [
                    'Jeśli nasze usługi obejmują płatności, zgadzasz się na podanie dokładnych informacji rozliczeniowych.',
                    'Wszystkie transakcje są ostateczne, chyba że nasza polityka zwrotów stanowi inaczej.'
                ],
            },
            {
                header: 'Ograniczenie odpowiedzialności',
                content: [
                    'Dążymy do zapewnienia dokładności i niezawodności naszej platformy, ale nie gwarantujemy nieprzerwanego działania usługi.',
                    'Nie ponosimy odpowiedzialności za jakiekolwiek bezpośrednie, pośrednie lub wynikowe szkody wynikające z korzystania z naszej platformy.'
                ],
            },
            {
                header: 'Zakończenie konta',
                content: [
                    'Użytkownicy mogą w każdej chwili zażądać usunięcia swojego konta.',
                    'Zastrzegamy sobie prawo do zawieszenia lub zamknięcia kont naruszających niniejsze warunki.'
                ],
            },
            {
                header: 'Zgodność z prawem norweskim i RODO',
                content: [
                    'Przestrzegamy prawa norweskiego i RODO w zakresie przetwarzania, przechowywania i praw związanych z danymi użytkownika.',
                    'Użytkownicy mają prawo do żądania dostępu, poprawienia lub usunięcia swoich danych.'
                ],
            },
            {
                header: 'Zmiany w umowie',
                content: [
                    'Zastrzegamy sobie prawo do aktualizacji tej umowy w dowolnym momencie.',
                    'Użytkownicy zostaną powiadomieni o istotnych zmianach przed ich wejściem w życie.'
                ],
            },
            {
                header: 'Informacje kontaktowe',
                content: [
                    'Jeśli masz pytania dotyczące tej umowy, możesz się z nami skontaktować pod adresem: [Twój e-mail kontaktowy].'
                ],
            }
        ]
    }
};


const returnPrivacyDocument = (record, lang) =>
{
    let outputObject = {};
    for (let [collectionName, languagesCollections] of Object.entries(userAgreement)) {
        outputObject[collectionName] = languagesCollections[record];
    }
    
    return outputObject[lang];
}

export {returnPrivacyDocument};