import React, { Component } from "react";
import { returnPrivacyDocument } from "../Lang/privacy";
//import { Row, Col, Form} from 'react-bootstrap';


class PopupCookiesPolicy extends Component {
  constructor () {
	  super();
	  this.state = {
    }
  }

  componentDidMount() {
  }
  
  render (){
    const cookiePolicy = returnPrivacyDocument('cookiePolicy', 'NO');
    return (
        <div className="popup-user-agreement">
          <h1>Retningslinjer for informasjonskapsler (Cookies)</h1>
          <div>
            {cookiePolicy.map((item, index) => {
              return (
                <div key={index}>
                  <h2>{item.header}</h2>
                  {item.content.map((content, index) => {
                    if (typeof content === 'string')
                    {return (
                      <p key={index}>{content}</p>
                    )}
                    if (typeof content === 'object' && Array.isArray(content))
                    {
                        return (
                    <ul key={index}>
                    {
                        content.map((subContent, subIndex)=> {
                          return (<li className="popup-user-agreement-list" key={subIndex}>{subContent}</li>);
                        
                    } )
                    }</ul>
                    )
                    }
                     })}
                </div>
              )
            })
            }
          </div>
        </div>
    );
  }
}

export default PopupCookiesPolicy;
