import React, { Component } from "react";


class SettingKnob extends Component {0

  click = (event) => {
    let {editSetting, type, setting} = {...this.props};
    const target = event.target;
    const pid = target.getAttribute('id');
    const option = target.getAttribute('name');
    let {open} = {...editSetting};
    open(type,option,setting.value,pid);
  }

  render (){
    let {editSetting} = {...this.props};
    let {pid} = {...editSetting};
    let {value, option, setting} = {...this.props.setting};
    value = value && value.length > 30 ? value.slice(0,30) + '...' : value;
    let setClass = value === true ? 'setting-knob-selected' : 'setting-knob'
      return (  
      <div className={setClass}>
        <div className="clickable-panel" id={pid} value={value} name={setting} onClick={this.click}></div>
        <div className="setting-knob-value">
           {option !== 'logo' && <p>{value}</p> }
        </div>
        <div className="setting-knob-option">
           <p>{option}</p> 
        </div>
      </div>
    );
  }
}

export default SettingKnob;
