import React, { Component } from "react";
import Cookies from 'universal-cookie';
import LogInPanel from '../UserPanel/LogInPanel';
import {serverRouteParser, requestObjectGenerator, serverConnect} from "../ServerRoutes/serverRoute";
import { Button, Form, Row } from "react-bootstrap";
import './../login_panel.css';
import { chooseLanguage } from "../Lang/langpack";
import RegisterUserContainer from "./RegisterUserContainer";
import FrontLoginPage from "../UserPanel/FrontLoginPage";

class LoginContainer extends Component {
  constructor () {
	  super();
	  this.state = {
        tll: false,
        tlp: false,
        rememberUser: true,
        logged: false,
        loginValid: true,
        register: false,
    }
    this.writeToState = this.writeToState.bind(this);
    this.logIn = this.logIn.bind(this);
    this.logInResponseHandler = this.logInResponseHandler.bind(this);
    this.deleteSessionCookies = this.deleteSessionCookies.bind(this);
    this.checkSessionId = this.checkSessionId.bind(this);
    this.checkSessionIdResponseHandler = this.checkSessionIdResponseHandler.bind(this);
}

  writeToState(position, value){
    this.setState({
      [position]: value
    })
  }

  logInResponseHandler = (connectionResult) => {
     // console.log(connectionResult);
      if (!connectionResult.error)
      {
        const passwordMatch = connectionResult.passwordMatch;
        if(!passwordMatch)
        {
          this.setState({
            logged: false,
            loginValid: false,
          })
        }
        if(passwordMatch)
        {
          const {uid, sessionid} = {...connectionResult};
         // console.log(uid);
         // console.log(sessionid);
          this.setCookie(uid, sessionid);
          this.setState({
            logged: true,
            loginValid: true,
            tll: false,
            tlp: false,
          }, this.props.downloadDataFromDB(uid, sessionid))
        }
      }
      if(connectionResult.error)
      {
        const {error} = {...connectionResult};
        const {showPopup, lang} = this.props;
        const {langinterface} = lang;
        const translation = {
          wrongLogin: chooseLanguage('wrongLogin', langinterface),
        };
        showPopup('error', translation.wrongLogin);
       // console.log('error occured: ' + error);
      }
    }

  logIn(){
    if (this.state.tll && this.state.tlp)
    {
      const authData = {
        email: this.state.tll,
        password: this.state.tlp
      };
      const connectionOptions = {
        record: 'user',
        purpose: 'login',
      }
      serverConnect(connectionOptions,authData, this.logInResponseHandler);
    }
    else
    {
      this.setState({
        loginValid: false,
      })
    }
  }

  setCookie = (uid, sessionid) => {
    const {rememberUser} = this.state;
    const cookies = new Cookies();
		let expires = new Date();
    const cookieExpiration = rememberUser ? 365 : 1/4;
    const cookieExpirationDate = cookieExpiration * 24 * 60 * 60 * 1000;
		expires.setTime(expires.getTime() + (cookieExpirationDate));
		cookies.set('sessionid', sessionid, expires.toUTCString(), {path: '/'});
		cookies.set('uid', uid, expires.toUTCString(), {path: '/'});
	}

	deleteSessionCookies() {
	const cookies = new Cookies();
	cookies.remove('sessionid', {path: '/'});
	cookies.remove('uid', {path: '/'});
    this.setState({
      logged: false,
      tll: false,
      tlp: false,
    })
	}

  checkSessionIdResponseHandler = (connectionResult) => {
    if (!connectionResult.error)
    {
      const {accessAllowed} = {...connectionResult};
      if (accessAllowed)
      {
        const cookies = new Cookies();
        const uid = cookies.get('uid') ? cookies.get('uid') : false;
        const sessionid = cookies.get('sessionid') ? cookies.get('sessionid') : false; 
        //// console.log(sessionid); 
        this.setState({
            logged: true,
        }, this.props.downloadDataFromDB(uid, sessionid))
      }
      if (!accessAllowed)
      {
        this.setState({
          logged: false,
        })
      }
    }
    if(connectionResult.error)
    {
      const {error} = {...connectionResult};
     // console.log('error occured: ' + error);
    }
  }
    
	checkSessionId() {
    const cookies = new Cookies();
		const uid = cookies.get('uid') ? cookies.get('uid') : false;
    const sessionid = cookies.get('sessionid') ? cookies.get('sessionid') : false;
		const authData = {uid, sessionid};
        //console.log(data);
    const connectionOptions = {
      record: 'user',
      purpose: 'relogin',
    }
    serverConnect(connectionOptions,authData, this.checkSessionIdResponseHandler);
 
  }

  saveSettingToState = (option, pid, value) =>{
    //zapisz do state

    this.setState({[option]: value});
  }

  openRegister = () =>{
    this.setState({
      register: true,
    });
  }
  closeRegister = () =>{
    this.setState({
      register: false,
    });
  }

  componentDidMount(){
    this.checkSessionId();

  }

  render (){
    const state = this.state;
    const {rememberUser} = state; 
    const {showPopup, lang, createNewUser} = this.props;
    return (
      <>
        <FrontLoginPage lang={lang}/>
        <div className='login-panel'>
          {!state.logged && !state.register && <Form>
            <Form.Row className='text-right'>
               <LogInPanel 
                  showPopup={showPopup}
                  openRegister={this.openRegister}
                  logIn={this.logIn} 
                  loginValid={state.loginValid} 
                  writeToState={this.writeToState}
                  lang={lang}
                  rememberUser={rememberUser}
                  saveSettingToState={this.saveSettingToState}
              />
          </Form.Row>
          </Form>}
          {!state.logged && state.register &&<Form>
            <Form.Row className='text-right'>
              <RegisterUserContainer 
                lang={lang}
                createNewUser={createNewUser}
                closeRegister={this.closeRegister}
                showPopup={showPopup}
               />
          </Form.Row>
          </Form>}         
        </div>
      </>
    );
  }
}

export default LoginContainer;
