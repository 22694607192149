import React, { Component } from "react";
import {serverRouteParser, requestObjectGenerator} from './../ServerRoutes/serverRoute';
import LoggedInDocumentsViewOptions from "./LoggedInDocumentsViewOptions";
import LoggedInDocumentsDisplay from "./LoggedInDocumentsDisplay";

import './../documents.css';

class LoggedInDocuments extends Component {
  constructor () {
	  super();
	  this.state = {
      view: 'calendar',
      filters: {
        timelist: false,
        invoice: false,
        offer: false,
      },
      zoom: 2,
      groupByCreateDate: true, //groupByCoverageOfDocument
    }
    this.toggleView = this.toggleView.bind(this);
    this.toggleGroup = this.toggleGroup.bind(this);
    this.toggleDocumentVisibility = this.toggleDocumentVisibility.bind(this);
  }


  zoomIn = () => {
   let {zoom} = this.state;
   zoom = zoom < 5 ? zoom+1 : zoom;
   zoom = zoom === 3 ? zoom+1 : zoom;
   this.setState({
    zoom
   })
  }
  zoomOut = () => {
   let {zoom} = this.state;
   zoom = zoom > 1 ? zoom-1 : zoom;
   zoom = zoom === 3 ? zoom-1 : zoom;
   this.setState({
    zoom
   })
  }



  
  toggleGroup(){
    let groupByCreateDate = this.state.groupByCreateDate;
    groupByCreateDate = !groupByCreateDate;
    this.setState({
      groupByCreateDate
    })
  }

  componentDidMount(){
    const view = this.props.view ?  this.props.view : 'calendar';
    this.setState({view});
  }


  toggleView(){
    const {modifyInterfaceSetting} = this.props.editSetting;
    const views = ['timeline','calendar'];
    let currentView = this.state.view;
    let newView = currentView===views[0] ? views[1] : views[0];
    modifyInterfaceSetting('timeline', newView);
    this.setState({
      view: newView
    })
  }
  toggleDocumentVisibility(event){
    const filterName = event.target.getAttribute('name');
    //console.log(filterName);
    let filters = this.state.filters;
    filters[filterName] = !filters[filterName];
    this.setState({
      filters
    })
  }

  render (){
    let {documents, view, lang} = {...this.props};
    let {filters} = {...this.state};
    let invoices =  documents.filter((document)=>document.type === 'faktura');
    let offers =  documents.filter((document)=>document.type === 'tilbud');
    let timelists =  documents.filter((document)=>document.type === 'timelist');
    let kreditnota =  documents.filter((document)=>document.type === 'kreditnota');
    let filteredDocuments = [].concat(timelists).concat(offers).concat(invoices).concat(kreditnota);
      
    documents = filteredDocuments.filter((document)=>{
      if (filters['timelist'] && !filters['invoice'] && !filters['offer'])
      {
        return document.type === 'timelist'
      }

      if (filters['timelist'] && filters['invoice'] && !filters['offer'])
      {
        return document.type === 'timelist' || document.type === 'faktura' || document.type === 'kreditnota' 
      }  

      if (filters['timelist'] && !filters['invoice'] && filters['offer'])
      {
        return document.type === 'timelist' || document.type === 'tilbud'
      }

      if (!filters['timelist'] && filters['invoice'] && filters['offer'])
      {
        return document.type === 'faktura' || document.type === 'tilbud' || document.type === 'kreditnota'
      }

      if (!filters['timelist'] && filters['invoice'] && !filters['offer'])
      {
        return document.type === 'faktura' || document.type === 'kreditnota'
      }

      if (!filters['timelist'] && !filters['invoice'] && filters['offer'])
      {
        return document.type === 'tilbud'
      }
      
      if (!filters['timelist'] && !filters['invoice'] && !filters['offer'])
      {
        return []
      }    

      if (filters['timelist'] && filters['invoice'] && filters['offer'])
      {
        return document.type === 'tilbud' || document.type === 'timelist' || document.type === 'faktura' || document.type === 'kreditnota'
      }
      
    })



    return (
      <div id="timeline-container">  
        {
          view !== 'cards' && <LoggedInDocumentsViewOptions
          toggleDocumentVisibility={this.toggleDocumentVisibility}
          filters={this.state.filters}
          view={this.state.view} 
          toggleView={this.toggleView}  
          lang={lang}    
          />
          }
        <LoggedInDocumentsDisplay 
          lang={lang}
          hud={this.props.hud}
          openFullPreview={this.props.openFullPreview} 
          groupByCreateDate={this.state.groupByCreateDate} 
          handleViewChange={this.props.handleViewChange} 
          projects={this.props.projects} 
          documents={documents} 
          documentOptions={this.props.documentOptions} 
          uid={this.props.uid} 
          view={this.state.view}
          zoom={{value: this.state.zoom, zoomIn: this.zoomIn, zoomOut: this.zoomOut}}
          />
      </div>
    );
  }
}

export default LoggedInDocuments;
