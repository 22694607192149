import React, { Component } from "react";
import Cookies from 'universal-cookie';
import Elsa from "./elsa/Elsa";
import PopupWindow from "./Popups/PopupWindow";
import {serverRouteParser, serverConnect, requestObjectGenerator} from "./ServerRoutes/serverRoute";
import LoginContainer from "./PageNavigation/LoginContainer";
import Main from "./Main";
import { chooseLanguage } from "./Lang/langpack";
import CookiesPopup from "./WebPrivacy/CookiesPopup";

class MainContainer extends Component {
  constructor () {
	  super();
	  this.state = {
      activeView: 'documents',
      addDay: false,
      agrees: {
        "newsletter": false,
        "dataanalysis": false
      },
      clients: [],
      days: [],
      documentFullPreview: false,
      documents: [],
      documentsFull: [],
      elsa: false,
      edit: {
        timelist: false,
        faktura: false,
        tilbud: false,
      },
      editSetting: false,
      editedDocument: {
        timelist: false,
        faktura: false,
        tilbud: false,
      },
      fakturadetails: {},
      hud: {
        previewFile: false,
        previewDay: false,
      },
      interface: {
        creator: true,
        timeline: 'calendar',
      },
      lang: 'EN',
      langinterface: 'EN',
      menu: {
        document: false,
        main: false,
        projects: false,
        clients: false,
        settings: false,
        settingWizard: false,
      },
      messages: [],
      name: '',
      newDocument: {
        timelist: false,
        faktura: false,
        tilbud: false,
      },
      partners: [],
      popup: false,
      projects: [],
      projectsFull: [],
      searchBox: false,
      selectedClient: false,
      selectedProject: false,
      tilbuddetails: {},
      uid: false,
    }
    this.showPopup = this.showPopup.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.createNewProject = this.createNewProject.bind(this);
    this.createNewClient = this.createNewClient.bind(this);    
    this.downloadDataFromDB = this.downloadDataFromDB.bind(this);
    this.downloadDataFromDBResponseHandler = this.downloadDataFromDBResponseHandler.bind(this);
    this.openFullPreview = this.openFullPreview.bind(this);
    this.closeFullPreview = this.closeFullPreview.bind(this);
    this.editDocument = this.editDocument.bind(this);
    this.repeatDocument = this.repeatDocument.bind(this);
    this.deleteDocument = this.deleteDocument.bind(this);
    this.createNewDocument = this.createNewDocument.bind(this);
    this.closeDocument = this.closeDocument.bind(this);
    this.openSearchBox = this.openSearchBox.bind(this);
    this.closeSearchBox = this.closeSearchBox.bind(this);
    this.generateDocument = this.generateDocument.bind(this);
    this.regenerateDocument = this.regenerateDocument.bind(this);
    this.saveDocument = this.saveDocument.bind(this);
  }

  //ELSA
  openElsa = () => {
    this.setState({
      elsa: true
    })
  } 
  closeElsa = () => {
    this.setState({
      elsa: false
    })
  } 


  //PROJECT DETAILS
  openProjectDetailsCard = (pid) => {
    let project = this.state.projectsFull.filter((project)=>project.pid === pid);
    project = project[0];
    let documents = this.state.documentsFull.filter((doc)=>doc.pid === pid);
    let menu = {
        document: false,
        main: false,
        projects: false,
        clients: false,
        settings: false,
      };
    project['projectDocuments'] = documents;
    this.setState(
      {
        selectedProject: project,
        menu
      }
      
      );
  }
  
  closeProjectDetailsCard = () => {
    this.setState({selectedProject: false});
  }

  //CLIENT DETAILS
  openClientDetailsCard = (cid) => {
    let client = this.state.clients.filter((client)=>client.cid === cid);
    client = client[0];
    let menu = {
        document: false,
        main: false,
        projects: false,
        clients: false,
        settings: false,
      };
    this.setState(
      {
        selectedClient: client,
        menu
      }
      
      );
  }
  closeClientDetailsCard = () => {
    this.setState({selectedClient: false});
  }

    //MENU
    openMenu(type){
      let {menu} = {...this.state};
      //console.log(type);
      menu[type] = true;
  
      this.setState({
        menu    
      })
    }
  
    closeMenu(type){
      //console.log(type);
      let {menu} = {...this.state};
      menu[type] = false;
      this.setState({
        menu    
      })
    }
  

    previewFile = (doc) => {
      let windowParam = window.innerWidth < 1081 ? 'horizontal' : 'veritical';
      let {hud} = this.state;
      if (doc === hud.previewFile || windowParam === 'horizontal')
      {
        this.openFullPreview(doc.did);
      }
      else if (typeof(doc) === 'string')
      {
        this.openFullPreview(doc);
      }
      else{
        hud.previewFile = doc;
        hud.previewDay = false;
        this.setState({
          hud
        })
      }
    }
   
    blankPreview = () => {
      let {hud} = this.state;
      hud.previewFile = false;
      hud.previewDay = false;
      this.setState({
        hud
      })
    }

  //SEARCHBOX
  closeSearchBox() {
    this.setState({
      searchBox: false,
    });
  }

  openSearchBox() {
    this.setState({
      searchBox: true,
    });
  }

  addDayResponseHandler = (connectionResult) => {
    if (!connectionResult.error)
    {
      let {days} = connectionResult;
      const {langinterface} = this.state;
      this.setState({days}, this.showPopup('info', chooseLanguage('daySaved', langinterface)));
    }
  }

  addDay = (timelist) => {
    const {uid} = {...this.state}
    const newDayOrder = {timelist, uid};
    const connectionOptions = {
      record: 'documents',
      purpose: 'addday',
    };
    serverConnect(connectionOptions, newDayOrder, this.addDayResponseHandler);
  }

  //OPEN EDIT DOC
  editDocument (did)
  {
    const documentFile = this.findDocument(did);
    //console.log(documentFile);
    const type = documentFile.type;
    if (type)
    {
      let newDocument = {...this.state.newDocument};
      let edit = {...this.state.edit};
      let editedDocument = {...this.state.editedDocument};
      newDocument[type] = true;
      edit[type] = did;
      editedDocument[type] = {...documentFile.content};
      editedDocument[type]['pid'] = documentFile.pid;
      editedDocument[type]['stapled'] = documentFile.stapled;      
      this.setState({
        edit,
        editedDocument,
        newDocument
      })
    }
    else
    {
      const {langinterface} = this.state;
      this.showPopup('error', chooseLanguage('errorEdit', langinterface));
    }
  }
  //HELP FUNC - FIND DOC
  findDocument = (did) => {
    let documentPosition = this.state.documentsFull.findIndex(x => x.did === did);
    let documentFile = {...this.state.documentsFull[documentPosition]};
    return documentFile;
  }

  //OPEN REPEAT DOC
  repeatDocument (did)
  {
    const documentFile = this.findDocument(did);
    //console.log(documentFile);
    const type = documentFile.type;
    if (type)
    {
      let newDocument = {...this.state.newDocument};
      let edit = {...this.state.edit};
      let editedDocument = {...this.state.editedDocument};
      let content = {...documentFile.content};
      newDocument[type] = true;
      edit[type] = true;
      editedDocument[type] = documentFile.content;
      editedDocument[type]['pid'] = documentFile.pid;
      this.setState({
        edit,
        editedDocument,
        newDocument
      })
    }
    else
    {
      const {langinterface} = this.state;
      this.showPopup('error', chooseLanguage('errorRepeat', langinterface));
    }
  }

  tilbudToFaktura = (did) =>
  {
    const type = 'faktura';
    let documentFile = this.findDocument(did);
    documentFile.type = type;
    documentFile.content.fakturaissue = new Date(); 
    documentFile.content.stapled = [did];
    //console.log(documentFile);
    if (type)
    {
      let newDocument = {...this.state.newDocument};
      let edit = {...this.state.edit};
      let editedDocument = {...this.state.editedDocument};
      let content = documentFile.content;
      content.pid = documentFile.pid;
      newDocument[type] = true;
      edit[type] = true;
      editedDocument[type] = documentFile.content;
      this.setState({
        edit,
        editedDocument,
        newDocument
      })
    }
    else
    {
      const {langinterface} = this.state;
      this.showPopup('error', chooseLanguage('errorFakturaTilbud', langinterface))
    }
  }

  sumUpTimelist = (daysArray, intimeArray, outtimeArray, breakpaidArray, breaktimeArray, kmArray, extraArray) => {
    let sumUpTimer = 0;
    let sumUpExtraTimer = 0;
		let sum = {
      regular: {
        hours: 0,
        minutes: 0,
        km: 0,
      },
      extra: {
        hours: 0,
        minutes: 0,
        km: 0,
      },     
    };
    breakpaidArray && breakpaidArray.map((isPaid, i)=>{
      let day = daysArray[i];
      let intime = intimeArray[i];
      let outtime = outtimeArray[i];
      let startDateString = ''+day.slice(6,10)+'-'+day.slice(3,5)+'-'+day.slice(0,2)+'T'+ intime + ':00';
      let endDateString = ''+day.slice(6,10)+'-'+day.slice(3,5)+'-'+day.slice(0,2)+'T'+ outtime + ':00';
      let start = new Date(startDateString);
      let end = new Date (endDateString);
      end = end > start ? end : end.setDate(end.getDate()+1);

			////console.log(start.toString() + ' ' + end.toString());
			let difference = end - start;
			difference = difference / 60 / 60 /1000;
      let km = kmArray && kmArray[i] ? parseInt(kmArray[i]) : 0;
			//////console.log('full time: ' + difference);
			if(!isPaid)
			{
				let breakDec = breaktimeArray[i]/60;
				//////console.log('break: ' + breakDec);
				difference = difference - breakDec;
			}
      if(extraArray && extraArray[i])
      {
        sum.extra.km =  sum.extra.km + km;
        sumUpExtraTimer = sumUpExtraTimer + difference;        
      }
      if((extraArray && !extraArray[i]) || !extraArray)
      {
        sum.regular.km = sum.regular.km + km;
        sumUpTimer = sumUpTimer + difference;
      }
    })
    
    sum.regular.hours = Math.floor(sumUpTimer / 1);
		sum.regular.minutes = sumUpTimer % 1;
		sum.regular.minutes = sum.regular.minutes * 60;
		sum.regular.minutes = sum.regular.minutes.toString();
		if (sum.regular.minutes.length === 1)
		{
			sum.regular.minutes = '0' + sum.regular.minutes;
		}
    sum.extra.hours = Math.floor(sumUpExtraTimer / 1);
		sum.extra.minutes = sumUpExtraTimer % 1;
		sum.extra.minutes = sum.extra.minutes * 60;
		sum.extra.minutes = sum.extra.minutes.toString();
		if (sum.extra.minutes.length === 1)
		{
			sum.extra.minutes = '0' + sum.extra.minutes;
		}

    return sum;
  }

  timelistToFaktura = (did) =>
  {
    const today = new Date();
    const documentFile = this.findDocument(did);
    const {fakturadetails, timelistdetails} = this.state;
    let {hourprice, kmprice, vat} = fakturadetails;
    vat = ''+vat;
    const daysArray = documentFile.content.days,
    intimeArray = documentFile.content.in,
    outtimeArray = documentFile.content.out,
    breakpaidArray = documentFile.content.breakpaid,
    breaktimeArray = documentFile.content.breaktime,
    kmArray = documentFile.content.km,
    extraArray = documentFile.content.extra,
    timelistnr = documentFile.content.timelistnr,
    username = documentFile.content.username,
    client = documentFile.content.client ? documentFile.content.client : {
      clientname: "",
      address: "",
      postnr: "",
      post: "",
      email: "",
      mva: "",
    };
    //licz time / km /timeextra /kmextra
    const sum = this.sumUpTimelist(daysArray, intimeArray, outtimeArray, breakpaidArray, breaktimeArray, kmArray, extraArray);
    //zrob pozycje w FV jesli ktoras wartosc powyzej 0
    const fakturaContent = (sum) => {
      let content = [];
      if (sum.regular.hours > 0 || (sum.regular.hours === 0 && sum.regular.minutes !== '00' ))
      {
        let {minutes, hours} = sum.regular;
        let multiplierValue = minutes === '00' ? 0 : minutes === '15' ? 0.25 : minutes === '30' ? 0.5 : minutes === '45' ? 0.75 : 0;
        multiplierValue = +multiplierValue + hours;
        let position = {
          description: 'Timelist ' + timelistnr + ': ' + username + ' - timer: ' + hours + ':' + minutes,
          netprice: +hourprice,
          multiplier: +multiplierValue,
          vat: vat,
        };
        content.push(position);
      }
      if (sum.regular.km > 0)
      {
        const {km} = sum.regular;
        let position = {
          description: 'Timelist ' + timelistnr + ': ' + username + ' - km: ' + km,
          netprice: +kmprice,
          multiplier: km,
          vat: vat,
        };
        content.push(position);
      }
      if (sum.extra.hours > 0 || (sum.extra.hours === 0 && sum.extra.minutes !== '00' ))
      {
        let {minutes, hours} = sum.extra;
        let multiplierValue = minutes === '00' ? 0 : minutes === '15' ? 0.25 : minutes === '30' ? 0.5 : minutes === '45' ? 0.75 : 0;
        multiplierValue = +multiplierValue + hours;
        let position = {
          description: 'Timelist ' + timelistnr + ': ' + username + ' - timer extra: ' + hours + ':' + minutes,
          netprice: +hourprice,
          multiplier: +multiplierValue,
          vat: vat,
        };
        content.push(position);
      }
      if (sum.extra.km > 0)
      {
        const {km} = sum.extra;
        let position = {
          description: 'Timelist ' + timelistnr + ': ' + username + ' - km extra: ' + km,
          netprice: +kmprice,
          multiplier: km,
          vat: vat,
        };
        content.push(position);
      }
      return content;
    }
    //console.log(sum);
    const type = 'faktura';
    let faktura = {
      stapled: [did],
      client,
      loggedprojectname: documentFile.content.project,
      pid: documentFile.pid,
      fakturaissue: today,
      fakturadue: undefined,
      content: fakturaContent(sum),
    }
    let newDocument = {...this.state.newDocument};
    let edit = {...this.state.edit};
    let editedDocument = {...this.state.editedDocument};
    newDocument[type] = true;
    edit[type] = true;
    editedDocument[type] = faktura;
    this.setState({
      edit,
      editedDocument,
      newDocument
    })
  }
  

  //DELETE DOC
  deleteDocumentResponseHandler = (connectionResult, deleteDocumentOrder) => {
    if (!connectionResult.error)
    {
      let documents = this.state.documents;
      let documentsFull = this.state.documentsFull;
      let {did} = {...connectionResult};
      const {langinterface} = this.state;
      //console.log('deleteFromState: '+ did);
      //console.log(did);
      let deletedDocumentPosition = documents.findIndex(document => document === did);
      let deletedFullDocumentPosition = documentsFull.findIndex(document => document.did === did);
      //console.log('deleteDocumentPosition:' + deletedDocumentPosition);
      //console.log('deleteFullDocumentPosition:' + deletedFullDocumentPosition);
      if (deletedDocumentPosition > -1 || deletedFullDocumentPosition > -1)  
        if (deletedFullDocumentPosition > -1) 
        {
          documentsFull.splice(deletedFullDocumentPosition, 1);
        }
        if (deletedDocumentPosition > -1) 
        {
          documents.splice(deletedDocumentPosition, 1);
        }
        this.setState({
          documents: documents,
          documentsFull: documentsFull, 
        }, this.showPopup('info', chooseLanguage('documentDeletedPopup', langinterface)))
      }
      if(connectionResult.error)
      {
        const {error} = {...connectionResult};
        //console.log('error occured: ' + error);
      }
  }
  
  deleteDocument = (did) => {
    const {uid} = {...this.state}
    const deleteDocumentOrder = {did, uid};
    const connectionOptions = {
      record: 'documents',
      purpose: 'delete',
    };
    serverConnect(connectionOptions, deleteDocumentOrder, this.deleteDocumentResponseHandler);
  }

  kreditnotaFakturaResponseHandler = (connectionResult) => {
    if (!connectionResult.error)
    {
      let {documents, documentsFull} = this.state;
      let doc = connectionResult.doc;
      const {langinterface} = this.state;
      //console.log(doc);
      documentsFull.unshift(doc);
      documents.push(doc.did);
      let documentFullPreview = doc.did;
      let fullDocumentPosition = documentsFull.findIndex(document => document.did === doc.stapled[0]);
      documentsFull[fullDocumentPosition]['stapled'].unshift(doc.did);
        this.setState({
          documentsFull: documentsFull, 
          documentFullPreview,
        }, this.showPopup('info', chooseLanguage('documentSavedPopup', langinterface)))    
      }
      if(connectionResult.error)
      {
        const {error} = {...connectionResult};
        //console.log('error occured: ' + error);
      }
  }

  kreditnotaFaktura = (did) => {
    const {uid} = {...this.state}
    const kreditnotaDocumentOrder = {did, uid};
    const connectionOptions = {
      record: 'documents',
      purpose: 'kreditnota',
    };
    serverConnect(connectionOptions, kreditnotaDocumentOrder, this.kreditnotaFakturaResponseHandler);
  }

  //OPEN NEW DOC
  createNewDocument(documentType) {
    const {langinterface, fakturadetails} = this.state;

    const type = documentType;
    const missingData = (type === 'tilbud' || type === 'faktura') && (!fakturadetails.companyname || !fakturadetails.mva || !fakturadetails.address || !fakturadetails.postnr || !fakturadetails.kontonr) ? true : false;
    if (!missingData)
    {
      if (type)
      {
        let newDocument = {...this.state.newDocument};
        let edit = {...this.state.edit};
        let editedDocument = {...this.state.editedDocument};
        newDocument[type] = true;
        edit[type] = false;
        editedDocument[type] = false;
        this.setState({
          edit,
          editedDocument,
          newDocument
        })
      }
      else
      {
        this.showPopup('error', chooseLanguage('errorCreate', langinterface))
      }
    }
    if (missingData)
    {
      this.showPopup('confirm', chooseLanguage('missingData', langinterface), ()=>this.openMenu('settingWizard'));
    }
  }

  //CLOSE NEW/EDIT DOC
  closeDocument(documentType){
    const type = documentType;
    const {langinterface} = this.state;
    if (type)
    {
      let newDocument = {...this.state.newDocument};
      let edit = {...this.state.edit};
      let editedDocument = {...this.state.editedDocument};
      newDocument[type] = false;
      edit[type] = false;
      editedDocument[type] = false;
      
      this.setState({
        edit,
        editedDocument,
        newDocument
      })
    }
    else
    {
      this.showPopup('error', chooseLanguage('errorClose', langinterface))
    }
  }
  

  //FULL DOC PREVIEW
 openFullPreview(did){
   let doc =  did;
   if (typeof(doc) === 'string')
   {
     this.setState({
       documentFullPreview: did
     })
   }
   if (typeof(doc) === 'object')
   {
    //console.log(doc);
    this.setState({
      documentFullPreview: doc.did
    })
   }
  }

  closeFullPreview(){
   this.setState({
     documentFullPreview: false
   })
  }

  //DOWNLOAD DATA FROM DB
  
  downloadDataFromDBResponseHandler = (connectionResult) => {
    //console.log(connectionResult);
    const {langinterface} = this.state;
    if (!connectionResult.error)
    {
      const {data, projects, documents} = {...connectionResult};
      const cookies = new Cookies();
      const uid = data.uid ? data.uid : cookies.get('uid') ? cookies.get('uid') : false;
      const sessionid = data.sessionid ? data.sessionid : cookies.get('sessionid') ? cookies.get('sessionid') : false; 
      //console.log(documents);
      this.setState({
        ...data,
        documentsFull: documents,
        sessionid,
        projectsFull: projects,
        uid,
      });
    }
    if(connectionResult.error)
    {
      const {error} = {...connectionResult};
      this.showPopup('error', chooseLanguage('errorDownload', langinterface))
    }
  }

  downloadDataFromDB(uid, sessionid) {
    //console.log(uid + ': ' + sessionid);
		const authData = {uid, sessionid};
    const connectionOptions = {
      record: 'user',
      purpose: 'dd',
    }
    serverConnect(connectionOptions,authData, this.downloadDataFromDBResponseHandler);
  }

  //SAVE DOCUMENT IN USER DB

  saveDocumentResponseHandler = (connectionResult) => 
  {
    if (!connectionResult.error)
    {
      const { doc }= {...connectionResult};
      const {type, did} = {...doc};
      //console.log('DID: ' + did);
      let {documents, documentsFull, edit, editedDocument, newDocument, documentFullPreview, fakturadetails, tilbuddetails, timelistdetails} = {...this.state};
      edit[type] = false;
      editedDocument[type] = false;
      newDocument[type] = false;
      documentFullPreview = did;
      if (doc.stapled && doc.stapled.length > 0)
      {
        let fullDocumentPosition = documentsFull.findIndex(document => document.did === doc.stapled[0]);
        documentsFull[fullDocumentPosition]['stapled'].unshift(doc.did);
      }
      if (!documents.includes(did))
      {
        //console.log('Dokumenty nie zawierają dokument!');
        documentsFull.push(doc);
        documents.push(did);
        if (type === 'tilbud') 
        {
          let {tilbudnr} = {...tilbuddetails};
          tilbudnr = +tilbudnr+1;    
          tilbuddetails.tilbudnr = tilbudnr;
        }
        if (type === 'timelist') 
        {
          let {timelistnr} = {...timelistdetails};
          timelistnr = +timelistnr+1;
          timelistdetails.timelistnr = timelistnr;
        }
        if (type === 'faktura') 
        {
          let {fakturanr} = {...fakturadetails};
          fakturanr = +fakturanr+1;
          fakturadetails.fakturanr = fakturanr;
        }
        if (type === 'kreditnota') 
        {
          let {kreditnotanr} = {...fakturadetails};
          kreditnotanr = +kreditnotanr+1;
          fakturadetails.kreditnotanr = kreditnotanr;
        }
      }
      if (documents.includes(did))
      {
        //console.log('Dokumenty zawierają taki dokument!');
        documentsFull = documentsFull.map((documentFull) => {
          if (documentFull.did === did)
          {
            return doc;
          }
          else
          {
            return documentFull;
          }
        })
        this.setState({documents, documentsFull, edit, editedDocument, newDocument, documentFullPreview, timelistdetails, fakturadetails, tilbuddetails}, this.showPopup('info', 'Document saved'));
      }
      //save doc in states
      //pdf already popup in _blank
    }
    if(connectionResult.error)
    {
      const {error} = {...connectionResult};
      const {langinterface} = this.state;
      this.showPopup('error', chooseLanguage('errorCreate', langinterface))
      //console.log('error occured: ' + error);
    }
  }

  saveDocument(type, documentContent){
    const uid = this.state.uid ? this.state.uid : '';
    let documentOrder = {...documentContent};
    documentOrder.uid = uid;
    documentOrder.type = type;
    const connectionOptions = {
      record: 'documents',
      purpose: 'save',
    }
    serverConnect(connectionOptions,documentOrder, this.saveDocumentResponseHandler);
  }

  generateDocumentResponseHandler = (connectionResult) =>
  {
    if (!connectionResult.error)
    {
      const { doc }= {...connectionResult};
      const {type, did} = {...doc};
      let {documents, documentsFull, edit, editedDocument, newDocument, documentFullPreview, fakturadetails, tilbuddetails, timelistdetails} = {...this.state};
      documentsFull.push(doc);
      documents.push(did);
      edit[type] = false;
      editedDocument[type] = false;
      newDocument[type] = false;
      documentFullPreview = did;
      if (type === 'tilbud') 
      {
        let {tilbudnr} = {...tilbuddetails};
        tilbudnr = +tilbudnr+1;    
        tilbuddetails.tilbudnr = tilbudnr;
      }
      if (type === 'timelist') 
      {
        let {timelistnr} = {...timelistdetails};
        timelistnr = +timelistnr+1;
        timelistdetails.timelistnr = timelistnr;
      }
      if (type === 'faktura') 
      {
        let {fakturanr} = {...fakturadetails};
        fakturanr = +fakturanr+1;
        fakturadetails.fakturanr = fakturanr;
      }
      this.setState({documents, documentsFull, edit, editedDocument, newDocument, documentFullPreview, timelistdetails, fakturadetails, tilbuddetails}, this.regenerateDocument(did));
      //save doc in states
      //pdf already popup in _blank
    }
    if(connectionResult.error)
    {
      const {error} = {...connectionResult};
      const {langinterface} = this.state;
      this.showPopup('error', chooseLanguage('errorCreate', langinterface));
      //console.log('error occured: ' + error);
    }
  }

  regenerateDocumentResponseHandler = (connectionResult, documentOrder) =>
  {
    if (!connectionResult.error)
    {
      const {langinterface} = this.state;
      this.showPopup('info', chooseLanguage('pdfReady', langinterface));
    
    }
    if(connectionResult.error)
    {
      const {error} = {...connectionResult};
      const {langinterface} = this.state;
      this.showPopup('error', chooseLanguage('errorCreate', langinterface));
      //console.log('error occured: ' + error);
    }
  }

  //GENERATE PDF DOC 
  generateDocument(type, documentContent){
    const uid = this.state.uid ? this.state.uid : '';
    let documentOrder = {...documentContent};
    documentOrder.uid = uid;
    documentOrder.type = type;
    const connectionOptions = {
      record: 'documents',
      purpose: 'save',
    }
    serverConnect(connectionOptions,documentOrder, this.generateDocumentResponseHandler);
  }

  varselFakturaResponseHandler = (connectionResult, documentOrder) =>
  {
    if (!connectionResult.error)
    {
      const today = new Date();
      //console.log(connectionResult);
      //console.log(documentOrder);
      const did = connectionResult.doc.did;
      const newVarsel = connectionResult.doc.content.varsel;
      //console.log(newVarsel);
      const {langinterface} = this.state;
      let docObj = this.state.documentsFull;
      let documentPosition = docObj.findIndex(x => x.did === did);

      //console.log(documentPosition);
      docObj[documentPosition]['content']['varsel'] = newVarsel;
      this.setState({
        documentsFull: docObj,
        hud: {
          previewFile: docObj[documentPosition],
          previewDay: false,
        }},  this.showPopup('info', chooseLanguage('varselReady', langinterface)));
    }
    /*if(connectionResult.error)
    {
      const {error} = {...connectionResult};
      const {langinterface} = this.state;
      this.showPopup('error', chooseLanguage('errorCreate', langinterface));
      console.log('error occured: ' + error);
    }*/
  }

  varselFaktura = (did) => {
    const {uid} = this.state;
    const documentOrder = {did, uid};
    const connectionOptions = {
      record: 'documents',
      purpose: 'varsel',
    }
    serverConnect(connectionOptions, documentOrder, this.varselFakturaResponseHandler);
  }

  regenerateDocument(did, varsel) {
    const uid = this.state.uid ? this.state.uid : '';
    const documentOrder = {did, uid};
    let documentPosition = this.state.documentsFull.findIndex(x => x.did === did);
    documentOrder.type = varsel ? 'varsel' : this.state.documentsFull[documentPosition].type;
    const connectionOptions = {
      record: 'documents',
      purpose: 'generate',
    }
    serverConnect(connectionOptions,documentOrder, this.regenerateDocumentResponseHandler);
  }

  //CREATE NEW PROJECT
  createNewProjectResponseHandler = (connectionResult, order) => {
    if (!connectionResult.error)
    {
      const {pid, projectname, uid} = {...connectionResult};
      let {projects, projectsFull }= {...this.state};
      let now = new Date();
      projects.push(
        {
          active:true,
          name: projectname,
          pcd: new Date(),
          pid: pid
        })
      projectsFull.push(
        {
          pid: pid,
          pcd: now,
          name: projectname,
          details: {
            bgdescription: "", 
            picture: "", 
            address: {
              address: "", 
              postnr: "", 
              post: ""
            }, 
            workhours: {
              in: "07:00", 
              out: "16:00"
            },
            owner: {
              name: "", 
              tlf: ""
            }, 
            others: [{name: "", description:"", tlf: ""}],
          },
          partners: [], //{uid: String, partnertype: Number, docflow: Number}	
          settings: {
            deadline: "", 
            exceptedtime: 0, 
            limit: 0, 
            critical: 0
          },
          structure: {
            type: 0, 
            grid: {
              master: [{uid: uid, minions: [{uid: "", duty: "", tlrequired: "", docflow: ""}]}], 
              boss: [{uid: "", minions: [{uid: "", duty: "", tlrequired: "", docflow: ""}]}], 
              pm: [{uid: "", minions: [{uid: "", duty: "", tlrequired: "", docflow: ""}]}], 
              private: [{uid: "", minions: [{uid: "", duty: "", tlrequired: "", docflow: ""}]}] 
            }
          },
          tasks: [],
        }
      )
      const {langinterface} = this.state;
      this.setState({
        projects},
        this.showPopup('info', chooseLanguage('projectCreated', langinterface))
        )
    }
    if(connectionResult.error)
    {
      const {error} = {...connectionResult};
      //console.log('error occured: ' + error);
    }
  }

  createNewProject(projectName){
    let data = {uid: this.state.uid, projectname: projectName};
    const connectionOptions = {
      record: 'projects',
      purpose: 'create',
    }
    serverConnect(connectionOptions,data,this.createNewProjectResponseHandler);
  }
  //MODIFY CLIENT SETTING
  modifyClientSettingResponseHandler = (connectionResult) => {
    const {langinterface} = this.state;
    if (!connectionResult.error)
    {
      //console.log('connection result: ' + connectionResult);
      const cid = connectionResult.cid;
      const setting = connectionResult.setting;
      const value = connectionResult.value;
      let clients = [...this.state.clients];
      clients = clients.map((client)=>{
        if (client.cid === cid)
        {
          const settingAddress = {
            clientname: 'clientname',
            address: 'address',
            postnr: 'postnr',
            post: 'post',
            email: 'email',
            mva: 'mva',
            remove: 'deleted',
            archive: 'archived',
          }

        const paths = (settingAddress[setting].split('.'));
        if (paths.length === 1)
        {
          client[paths[0]] = value;
        }
        if (paths.length === 2)
        {
          client[paths[0]][paths[1]] = value;
        }
        if (paths.length === 3)
        {
          client[paths[0]][paths[1]][paths[2]] = value;
        }       
         return client;
        }
        else
        {
          return client;
        }  
      });
      let editSetting = false;
      this.setState({
        editSetting,
        clients},
        this.showPopup('info', chooseLanguage('settingSaved', langinterface))  
      );
    }
    if (connectionResult.error)
    {
      this.showPopup('error', chooseLanguage('errorSaveSetting', langinterface));
    }
  }


  modifyClientSetting = (cid, setting, value) =>
  {
    let data = {uid: this.state.uid, cid: cid, value: value};
    const connectionOptions = {
      record: 'clients',
      purpose: 'settings',
      link: setting
    }
    serverConnect(connectionOptions, data, this.modifyClientSettingResponseHandler);
  } 

    //MODIFY PROJECT SETTING
    modifyProjectSettingResponseHandler = (connectionResult) => {
      const {langinterface} = this.state;
      if (!connectionResult.error)
      {
        //console.log('connection result: ' + connectionResult);
        const pid = connectionResult.pid;
        const setting = connectionResult.setting;
        const value = connectionResult.value;
        let projectsFull = [...this.state.projectsFull];
        projectsFull = projectsFull.map((project)=>{
          if (project.pid === pid)
          {
            const settingAddress = {
              projectname: 'name',
              projectaddress: 'details.address.address',
              projectpostnr: 'details.address.postnr',
              projectpost: 'details.address.post',
              bgdescription: 'details.bgdescription',
              critical: 'settings.critical',
              exceptedtime: 'settings.exceptedtime',
              deadline: 'settings.deadline',
              limit: 'settings.limit',
              remove: 'settings.deleted',
              archive: 'settings.archived',
              projectin: 'details.workhours.in',
              projectout: 'details.workhours.out',
            }
  
          const paths = (settingAddress[setting].split('.'));
          if (paths.length === 1)
          {
            project[paths[0]] = value;
          }
          if (paths.length === 2)
          {
            project[paths[0]][paths[1]] = value;
          }
          if (paths.length === 3)
          {
            project[paths[0]][paths[1]][paths[2]] = value;
          }       
           return project;
          }
          else
          {
            return project;
          }  
        });
        let editSetting = false;
        this.setState({
          editSetting,
          projectsFull},
          this.showPopup('info', chooseLanguage('settingSaved', langinterface))
        );
      }
      if (connectionResult.error)
      {
        this.showPopup('error', chooseLanguage('errorSaveSetting', langinterface));
      }
    }
  
  
    modifyProjectSetting = (pid, setting, value) =>
    {
      let data = {uid: this.state.uid, pid: pid, value: value};
      const connectionOptions = {
        record: 'projects',
        purpose: 'settings',
        link: setting
      }
      serverConnect(connectionOptions, data, this.modifyProjectSettingResponseHandler);
    } 
    //MODIFY SETTING
  modifySettingResponseHandler = (connectionResult) => {
    const {langinterface} = this.state;
    //console.log(connectionResult);
    if (!connectionResult.error)
    {
      const setting = connectionResult.setting;
      const value = connectionResult.value;
          const settingAddress = {
            companyname: 'fakturadetails.companyname',
            companyaddress: 'fakturadetails.address',
            companypostnr: 'fakturadetails.postnr',
            companypost: 'fakturadetails.post',
            companykontonr: 'fakturadetails.kontonr',
            mva: 'fakturadetails.mva',
            vat: 'fakturadetails.vat',
            fakturadue: 'fakturadetails.paymentdue',
            giro: 'fakturadetails.giro',
            hourprice: 'fakturadetails.hourprice',
            kmprice: 'fakturadetails.kmprice',
            fakturanr: 'fakturadetails.fakturanr',
            logo: 'fakturadetails.logo',
            tilbudnr: 'tilbuddetails.tilbudnr',
            tilbuddue: 'tilbuddetails.tilbuddue',
            timelistnr: 'timelistdetails.timelistnr',
            timelistbreaktime: 'timelistdetails.breaktime',
            timelistbreakpaid: 'timelistdetails.breakpaid',
            timelistin: 'timelistdetails.in',
            timelistout: 'timelistdetails.out',
            km: 'timelistdetails.km',
            lang: 'lang',
            interfacelang: 'langinterface',
            craft: 'craft',
            username: 'name',
          }

        let editSetting = false;
        const paths = (settingAddress[setting].split('.'));
        if (paths.length === 1)
        {
          this.setState({
            editSetting,
            [paths[0]]: value,
          },
          this.showPopup('info', chooseLanguage('settingSaved', langinterface)))
        }
        if (paths.length === 2)
        {
          const path = this.state[paths[0]];
          path[paths[1]] = value;
          this.setState({
            editSetting,
            [paths[1]]: path,
          },
          this.showPopup('info', chooseLanguage('settingSaved', langinterface))
        );
        }
    }
    if (connectionResult.error)
    {
      const {error} = {...connectionResult};
      //console.log('ERROR');
      //console.log(error);
      this.showPopup('error', chooseLanguage('errorSaveSetting', langinterface));
    }
  }
  modifySetting = (setting, value) =>
  {
    let data = {uid: this.state.uid, value: value};
    const connectionOptions = {
      record: 'settings',
      purpose: 'settings',
      link: setting
    }
    serverConnect(connectionOptions, data, this.modifySettingResponseHandler);
  } 
  modifyInterfaceSettingResponseHandler = (connectionResult) => {
    const {langinterface} = this.state;
    //console.log(connectionResult);
    if (!connectionResult.error)
    {
      const setting = connectionResult.setting;
      const value = connectionResult.value;
          const settingAddress = {
            creator: 'interface.creator',
            timeline: 'interface.timeline',
          }

        let editSetting = false;
        const paths = (settingAddress[setting].split('.'));
        if (paths.length === 1)
        {
          this.setState({
            editSetting,
            [paths[0]]: value,
          })
        }
        if (paths.length === 2)
        {
          const path = this.state[paths[0]];
          path[paths[1]] = value;
          this.setState({
            editSetting,
            [paths[1]]: path,
          }
        );
        }
    }
    if (connectionResult.error)
    {
      const {error} = {...connectionResult};
      //console.log('ERROR');
      //console.log(error);
      this.showPopup('error', chooseLanguage('errorSaveSetting', langinterface));
    }
  }
  modifyInterfaceSetting = (setting, value) =>
  {
    let data = {uid: this.state.uid, value: value};
    const connectionOptions = {
      record: 'settings',
      purpose: 'settings',
      link: setting
    }
    serverConnect(connectionOptions, data, this.modifyInterfaceSettingResponseHandler);
  } 

  passwordChangeResponseHandler = (connectionResult) => {
    const {langinterface} = this.state;
    if (!connectionResult.error)
    {
      this.setState({
        editSetting: false},
      this.showPopup('info', chooseLanguage('settingSaved', langinterface)));
    }
    if (connectionResult.error)
    {
      const {error} = {...connectionResult};
      this.showPopup('error', chooseLanguage('errorSaveSetting', langinterface));
    }
  }

  emailChangeResponseHandler = (connectionResult) => {
    const {langinterface} = this.state;
    //console.log(connectionResult);
    if (!connectionResult.error)
    {
      this.setState({
        editSetting: false},
      this.showPopup('confirm', chooseLanguage('emailChangeConfirmLogout', langinterface), this.logout));
    }
    if (connectionResult.error)
    {
      const {error} = {...connectionResult};
      this.showPopup('error', chooseLanguage('errorSaveSetting', langinterface));
    }
  }

  emailChange = (emailObject) =>
  {
    const cookies = new Cookies();
    const uid = cookies.get('uid') ? cookies.get('uid') : false;
    const sessionid = cookies.get('sessionid') ? cookies.get('sessionid') : false;  
    let data = {uid, sessionid, data: emailObject};
    const connectionOptions = {
      record: 'user',
      purpose: 'emailchange'
    }
    serverConnect(connectionOptions, data, this.emailChangeResponseHandler);
  } 

  passwordChange = (passwordObject) =>
  {
    const cookies = new Cookies();
    const uid = cookies.get('uid') ? cookies.get('uid') : false;
    const sessionid = cookies.get('sessionid') ? cookies.get('sessionid') : false;  
    let data = {uid, sessionid, data: passwordObject};
    const connectionOptions = {
      record: 'user',
      purpose: 'passchange'
    }
    serverConnect(connectionOptions, data, this.passwordChangeResponseHandler);
  } 
  
  //EDIT SETTING
  openEditSetting = (type, option, value, pid) =>
  {
    this.setState({
      editSetting: {
        type: type,
        option: option,
        value: value,
        pid: pid
      }
    })
  }
  closeEditSetting = () =>
  {
    this.setState({
      editSetting: false,
    })
  }


  //NEW CLIENT
  createNewClientResponseHandler = (connectionResult, order) => {
    const {langinterface} = this.state;
    if (!connectionResult.error)
    {
      //console.log(connectionResult);
      let {clients} = this.state;
      let {clientData} = connectionResult;
      clients.unshift(clientData);
      //console.log(clients);
      this.setState({
        clients},
        this.showPopup('info', chooseLanguage('clientSaved', langinterface))
      );
    }
    if (connectionResult.error)
    {
      const {error} = {...connectionResult};
      //console.log('error occured: ' + error);
    }
  }

  setCookie = (uid, sessionid) => {
    const cookies = new Cookies();
		let expires = new Date();
    const cookieExpiration = 1/4;
    const cookieExpirationDate = cookieExpiration * 24 * 60 * 60 * 1000;
		expires.setTime(expires.getTime() + (cookieExpirationDate));
		cookies.set('sessionid', sessionid, expires.toUTCString(), {path: '/'});
		cookies.set('uid', uid, expires.toUTCString(), {path: '/'});
	}

  createNewUserResponseHandler = (connectionResult) => {
    //console.log(connectionResult);
    const {langinterface} = this.state;
    if (!connectionResult.error)
    {
      const {data, projects, documents} = {...connectionResult};
      const sessionid = data.session.id;
      const {uid} = data;
       this.setCookie(uid, sessionid);
      //console.log(documents);
      this.setState({
        ...data,
        documentsFull: documents,
        sessionid,
        projectsFull: projects,
        uid,
      });
    }
    if(connectionResult.error)
    {
      const {error} = {...connectionResult};
      this.showPopup('error', chooseLanguage('errorDownload', langinterface))
    }
  }

  createNewClient(client){
    //console.log(client);
    let data = {"uid": this.state.uid, "client": client};
    const connectionOptions = {
      record: 'clients',
      purpose: 'create',
    }
    serverConnect(connectionOptions,data, this.createNewClientResponseHandler);
  }

    //NEW User
  
    createNewUser = (user) => {
      //console.log(user);
      user.lang = this.state.lang;
      user.langinterface = this.state.langinterface;
      let data = {"user": user};
      const connectionOptions = {
        record: 'user',
        purpose: 'register',
      }
      serverConnect(connectionOptions,data, this.createNewUserResponseHandler);
    }

  //POPUP
  showPopup = (type, content, callback) => {
    const acceptCallback = function(callback, closeAction)
    {
      callback();
      closeAction();
    }
    type === 'info' && this.setState({
      popup: {
        type: type,
        content: content,
        callback: callback ? ()=> acceptCallback(callback, this.closePopup) : this.closePopup,
        abort: this.closePopup,
      }
    }, () => setTimeout(()=>this.closePopup(), 7000));
    type === 'warning' && this.setState({
      popup: {
        type: type,
        content: content,
        callback: callback ? ()=> acceptCallback(callback, this.closePopup) : this.closePopup,
        abort: this.closePopup,
      }
    }, () => setTimeout(()=>this.closePopup(), 7000));
    type !== 'info' && type !== 'warning' && this.setState({
      popup: {
        type: type,
        content: content,
        callback: callback ? ()=> acceptCallback(callback, this.closePopup) : this.closePopup,
        abort: this.closePopup,
      }
    })
  }
  
  closePopup = () => {
    this.setState({
      popup: false    
    })
  }




  logoutResponseHandler = (connectionResult) => {
    if (!connectionResult.error)
    {
      const cookies = new Cookies();
      cookies.remove('sessionid', {path: '/'});
      cookies.remove('uid', {path: '/'});
      const langinterface = Navigator.language === "pl-PL" ? 'PL' : Navigator.language === "no-NO" ? 'NO' : 'EN';
        this.setState({
          activeView: 'documents',
          agrees: {
            "newsletter": false,
            "dataanalysis": false
          },
          clients: [],
          days: [],
          documentFullPreview: false,
          documents: [],
          documentsFull: [],
          edit: {
            timelist: false,
            faktura: false,
            tilbud: false,
          },
          editSetting: false,
          editedDocument: {
            timelist: false,
            faktura: false,
            tilbud: false,
          },
          fakturadetails: {},
          hud: {
            previewFile: false,
            previewDay: false,
          },
          interface: {
            creator: true,
            timeline: 'calendar',
          },
          lang: 'NO',
          langinterface: 'EN',
          menu: {
            document: false,
            main: false,
            projects: false,
            clients: false,
            settings: false,
          },
          messages: [],
          name: '',
          newDocument: {
            timelist: false,
            faktura: false,
            tilbud: false,
          },
          partners: [],
          popup: false,
          projects: [],
          projectsFull: [],
          searchBox: false,
          selectedClient: false,
          selectedProject: false,
          tilbuddetails: {},
          uid: false,
        })
    }
    if(connectionResult.error)
    {
      const {error} = {...connectionResult};
      //console.log('error occured: ' + error);
    }
  }

  deleteImportedDays = (indexArray) => {
    if (indexArray && indexArray.length > 0)
    {
      let {days} = this.state;
      //indexArray.map((daysArrayIndex, index) => (console.log(daysArrayIndex)));
      indexArray.map((daysArrayIndex, index) => (daysArrayIndex !== false && days.splice(daysArrayIndex, 1)));
      this.setState({days});
    }
  }


  //USER LOGOUT
  logout = () => {
    const cookies = new Cookies();
		const uid = cookies.get('uid') ? cookies.get('uid') : false;
    const sessionid = cookies.get('sessionid') ? cookies.get('sessionid') : false;
		const authData = {uid, sessionid};
        ////console.log(data);
    const connectionOptions = {
      record: 'user',
      purpose: 'relogin',
    }
  serverConnect(connectionOptions, authData, this.logoutResponseHandler);
}



 componentDidMount() {
  const lang = 'NO';
  const langinterface = Navigator.language === "pl-PL" ? 'PL' : Navigator.language === "no-NO" ? 'NO' : 'EN';
  this.setState({
    lang, langinterface
  })
}


  render (){
    const {
      elsa,
      popup,
      uid,
      agrees,
      craft,
      clients,
      days,
      documentsFull,
      fakturadetails,
      lang,
      langinterface,
      menu,
      name,
      messages,
      projectsFull,
      timelistdetails,
      tilbuddetails,
      tasks,
      newDocument,
      edit,
      editedDocument,
      editSetting,
      activeView,
      selectedProject,
      selectedClient,
    } = {...this.state};
      let docFullPreview = documentsFull && documentsFull.filter((doc) => {
        return doc.did === this.state.documentFullPreview
      })
      let windowParam = window.innerHeight > window.innerWidth ? 'horizontal' : 'veritical';
    	const cookies = new Cookies();
	    const cookiesAccepted = cookies.get('cookies-accepted') ? cookies.get('cookies-accepted') : false;
      return (
      <div>
      {popup && <PopupWindow 
        addDay={this.addDay}
        agrees={agrees} 
        fakturadetails={fakturadetails} 
        timelistdetails={timelistdetails} 
        tilbuddetails={tilbuddetails} 
        createNewClient={this.createNewClient} 
        createNewProject={this.createNewProject}
        createNewUser={this.createNewUser}
        popup={popup}
        lang={{doc: lang, langinterface: langinterface}}
        />}
        <nav>
        </nav>
        <header>
        </header>
        <main>
        {uid && <Main
            agrees={agrees}
            activeView={activeView}
            clients={clients}
            lang={{doc: lang, langinterface: langinterface}}
            fullPreview={{
              close: this.closeFullPreview, 
              open: this.openFullPreview, 
              doc: docFullPreview && docFullPreview[0] ? docFullPreview[0] : false}}
            closeMenu={this.closeMenu}
            clientController={{
              open: this.openClientDetailsCard,
              close: this.closeClientDetailsCard
            }}
            createNewClient={this.createNewClient}
            createNewDocument={this.createNewDocument}
            createNewProject={this.createNewProject}
            craft={craft}
            days={days}
            deleteImportedDays={this.deleteImportedDays}
            documents={documentsFull}
            documentOptions={{
              close: this.closeDocument,
              delete: this.deleteDocument, 
              edit: this.editDocument, 
              find: this.findDocument,
              generate: this.generateDocument,
              kreditnota: this.kreditnotaFaktura,
              repeat: this.repeatDocument, 
              regenerate: this.regenerateDocument,
              save: this.saveDocument, 
              varsel: this.varselFaktura,
              tilbudToFaktura: this.tilbudToFaktura,
              timelistToFaktura: this.timelistToFaktura,
              }}
            documentController={{
              newDocument: newDocument, 
              edit: edit, 
              editedDocument: editedDocument}}
            editSetting={{
              open: this.openEditSetting,
              close: this.closeEditSetting,
              setting: editSetting,
              modifySetting: this.modifySetting,
              passwordChange: this.passwordChange,
              emailChange: this.emailChange,
              modifyInterfaceSetting: this.modifyInterfaceSetting,
              modifyClientSetting: this.modifyClientSetting,
              modifyProjectSetting: this.modifyProjectSetting,
            }}
            hud = {{
              previewFile: {
                open: this.previewFile,
                close: this.blankPreview,
                file: this.state.hud.previewFile,
              }
            }}
            projectController={{
              open: this.openProjectDetailsCard,
              close: this.closeProjectDetailsCard
            }}
            fakturadetails={fakturadetails}
            menu={menu}
            username={name}
            messages={messages}
            openMenu={this.openMenu}
            openElsa={this.openElsa}
            projects={projectsFull}
            popup={popup}
            showPopup={this.showPopup}
            selectedProject={selectedProject}
            selectedClient={selectedClient}
            tilbuddetails={tilbuddetails}
            timelistdetails={timelistdetails}
            tasks={tasks}
            logout={this.logout}
            modifyProjectSetting={this.modifyProjectSetting}
            windowParam = {windowParam}
            interface={this.state.interface}
            />}
            {!uid && <LoginContainer
             lang={{doc: lang, langinterface: langinterface}} 
             showPopup={this.showPopup} 
             downloadDataFromDB={this.downloadDataFromDB}
             createNewUser={this.createNewUser}
             />}
        </main>
        <Elsa lang={langinterface} elsa={elsa} openElsa={this.openElsa} closeElsa={this.closeElsa}/>
        <footer>

        </footer>
        {!cookiesAccepted && <CookiesPopup showPopup={this.showPopup}/>}
      </div>
    );
  }
}

export default MainContainer;
