import React, { useState } from "react";
import axios from "axios";

const ImageComponent = ({value ,saveValue}) => {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setPreview(URL.createObjectURL(selectedFile));
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Please select an image first");
      return;
    }

    const formData = new FormData();
    formData.append("image", file); // 'image' is the field name

    try {
      const response = await axios.post("http://localhost:3003/api/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      saveValue(0, 0, response.data.filename);
      alert("Upload successful! " + response.data.filename);
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Upload failed!");
    }
  };



  return (
    <div>
      {preview && <img src={preview} alt="Preview" style={{ width: 200 }} />}
      {!preview && value && <img src={'http://localhost:3003/api/logo/'+value} alt="Preview" style={{ width: 200 }} />}      
      <input type="file" accept="image/*" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>
    </div>
  );
};

export default ImageComponent;
