import React, { Component } from "react";
//import { Row, Col, Form} from 'react-bootstrap';


class PopupAd extends Component {
  constructor () {
	  super();
	  this.state = {
    }
  }

  componentDidMount() {
  }
  
  render (){

    return (
        <div className="popup-ad">
          <div>
          </div>
        </div>
    );
  }
}

export default PopupAd;
