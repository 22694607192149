import React, { Component } from "react";
import { Row, Col, Form} from 'react-bootstrap';

class GrossValue extends Component {
  constructor () {
	  super();
	  this.state = {
    }
    this.saveBasis = this.saveBasis.bind(this);
  }

  componentDidMount() {
  }

  saveBasis(event){
    let record = event.target.id,
      value = event.target.value;
      this.props.writeToState(record, this.props.index, value);
  }

  render (){
    let gross = 0;
    let {vat, multiplier, netPrice} = {...this.props};
    vat = +vat !== 0 ? +vat/100 : 0;
    //console.log(vat);
    gross = (netPrice * multiplier) + (netPrice * multiplier)*(vat);
    gross = gross.toFixed(2);
    return (
      <Form.Label className='text-right' column lg={{span: '1', offset: 2}}><h4>{gross}</h4></Form.Label>
    );
  }
}

export default GrossValue;
