import React, { Component } from "react";
import DocumentOptions from "./DocumentOptions";
import './../timeline.css';
import { dateToSlashString } from "../Features/dateFeatures";
import StapledDocuments from "./StapledDocuments";
import DocumentExportOptions from "../FormComponents/DocumentExportOptions";
import { chooseLanguage } from "../Lang/langpack";
class TimelistPreview extends Component {
  constructor () {
	  super();
	  this.state = {
      exportOptions: false,
    }
    this.fullPreview = this.fullPreview.bind(this);
  }

 
  componentDidMount() {

  }

  getYearOfDate(date){
    ////console.log("data do konwersji: " + date);
    let dte= new Date(date.slice(6,10), +date.slice(3,5)-1, date.slice(0,2));
    ////console.log(dte);
    const fullYear = dte.getFullYear();
    ////console.log(fullYear);
    return fullYear;
  }


  getYear(date){
    let dte = new Date(date);
    return dte.getFullYear();
  }
  
  fullPreview(event){
    let did = event.target.id;
    //console.log(did);
    this.props.openFullPreview(this.props.document);
  }

  getWeekOfDate(date){
    //console.log(date);
    Date.prototype.getWeek = function() {
      var date = new Date(this.getTime());
      ////console.log(date);
      date.setHours(0, 0, 0, 0);
      date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
      ////console.log(date);
      var week1 = new Date(date.getFullYear(), 0, 4);
      return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
    }
    let dte= new Date(date.slice(6,10), +date.slice(3,5)-1, date.slice(0,2));
    let week = dte.getWeek();
    return week;
  }


  sumUpFaktura = (content) => {
    //console.log(content);
    let totalPrice = 0;
		let mvaCalculation = [0,0,0,0];
		let mvaBase = [0,0,0,0]
		content.map((position, index)=>
		{
			let basePrice = +position.netprice;
			let amount = +position.multiplier;
			let sumPrice = basePrice * amount;
			let mva = +position.vat;
			let mvaPrice = sumPrice * (mva/100);
			let total = sumPrice + mvaPrice;
			totalPrice = totalPrice + total;
			switch (mva) {
				case 0: 
					mvaCalculation[0] = mvaCalculation[0]+mvaPrice;
					mvaBase[0] = mvaBase[0] + sumPrice;
				break;
				case 12: 
					mvaCalculation[1] = mvaCalculation[1]+mvaPrice;
					mvaBase[1] = mvaBase[1] + sumPrice;
				break;				
				case 15: 
					mvaCalculation[2] = mvaCalculation[2]+mvaPrice;
					mvaBase[2] = mvaBase[2] + sumPrice;
				break;			
				case 25: 
					mvaCalculation[3] = mvaCalculation[3]+mvaPrice;
					mvaBase[3] = mvaBase[3] + sumPrice;
				break;			
			}
    });
    const result = {totalPrice, mvaCalculation, mvaBase};
    return result;
  }


  sumUpTimelist = (daysArray, intimeArray, outtimeArray, breakpaidArray, breaktimeArray, kmArray, extraArray) => {
    let sumUpTimer = 0;
    let sumUpExtraTimer = 0;
		let sum = {
      regular: {
        hours: 0,
        minutes: 0,
        km: 0,
      },
      extra: {
        hours: 0,
        minutes: 0,
        km: 0,
      },     
    };
    breakpaidArray && breakpaidArray.map((isPaid, i)=>{
      let day = daysArray[i];
      let intime = intimeArray[i];
      let outtime = outtimeArray[i];
      let startDateString = ''+day.slice(6,10)+'-'+day.slice(3,5)+'-'+day.slice(0,2)+'T'+ intime + ':00';
      let endDateString = ''+day.slice(6,10)+'-'+day.slice(3,5)+'-'+day.slice(0,2)+'T'+ outtime + ':00';
      let start = new Date(startDateString);
      let end = new Date (endDateString);
      end = end > start ? end : end.setDate(end.getDate()+1);

			////console.log(start.toString() + ' ' + end.toString());
			let difference = end - start;
			difference = difference / 60 / 60 /1000;
      let km = kmArray && kmArray[i] ? parseInt(kmArray[i]) : 0;
			////console.log('full time: ' + difference);
			if(!isPaid)
			{
				let breakDec = breaktimeArray[i]/60;
				////console.log('break: ' + breakDec);
				difference = difference - breakDec;
			}
      if(extraArray && extraArray[i])
      {
        sum.extra.km =  sum.extra.km + km;
        sumUpExtraTimer = sumUpExtraTimer + difference;        
      }
      if((extraArray && !extraArray[i]) || !extraArray)
      {
        sum.regular.km = sum.regular.km + km;
        sumUpTimer = sumUpTimer + difference;
      }
    })
    
    sum.regular.hours = Math.floor(sumUpTimer / 1);
		sum.regular.minutes = sumUpTimer % 1;
		sum.regular.minutes = sum.regular.minutes * 60;
    sum.regular.minutes = sum.regular.minutes.toFixed(0);
		sum.regular.minutes = sum.regular.minutes.toString();
		if (sum.regular.minutes.length === 1)
		{
			sum.regular.minutes = '0' + sum.regular.minutes;
		}
    sum.extra.hours = Math.floor(sumUpExtraTimer / 1);
		sum.extra.minutes = sumUpExtraTimer % 1;
		sum.extra.minutes = sum.extra.minutes * 60;
    sum.extra.minutes = sum.extra.minutes.toFixed(0);
		sum.extra.minutes = sum.extra.minutes.toString();
		if (sum.extra.minutes.length === 1)
		{
			sum.extra.minutes = '0' + sum.extra.minutes;
		}

    return sum;
  }


  sumUpDay = (day, intime, outtime, breakpaid, breaktime) => {
    let startDateString = ''+day.slice(6,10)+'-'+day.slice(3,5)+'-'+day.slice(0,2)+'T'+ intime + ':00';
    let endDateString = ''+day.slice(6,10)+'-'+day.slice(3,5)+'-'+day.slice(0,2)+'T'+ outtime + ':00';
    let start = new Date(startDateString);
    let end = new Date (endDateString);
    end = end > start ? end : end.setDate(end.getDate()+1);

    let difference = end - start;
    difference = difference / 60 / 60 /1000;
    let hours, minutes;
    //console.log('full time: ' + difference);
    if(breakpaid === false)
    {
      let breakDec = breaktime/60;
      //console.log('break: ' + breakDec);
      difference = difference - breakDec;
    }
    hours = Math.floor(difference / 1);
    minutes = difference % 1;
    minutes = minutes * 60;
    minutes = minutes.toFixed(0);
    minutes = minutes.toString();
    if (minutes.length === 1)
    {
      minutes = '0' + minutes;
      this.sumUpTimelist(difference);
    }
    return ''+hours+':'+minutes;
  }

  breaktimeToString = (breaktime) => {
    switch (breaktime){
      case '0': 
        return '0:00';
      break;
      case '15': 
        return '0:15';
      break;
      case '30': 
        return '0:30';
      break;
      case '45': 
      return '0:45';
      break;
      case '60': 
        return '1:00';
      break;
      case '120': 
        return '2:00';
      break;
      default: 
       return '';
    }
  }

  numberWithSpaces = (x) => {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(",");
  }
  

  previewTimelist = (documentFile) => {
    //console.log(documentFile);
    let sumUp;
        sumUp = this.sumUpTimelist(documentFile.content.days, documentFile.content.in, documentFile.content.out, documentFile.content.breakpaid, documentFile.content.breaktime, documentFile.content.km, documentFile.content.extra);
        const week = this.getWeekOfDate(documentFile.content.days[0]) === this.getWeekOfDate(documentFile.content.days[documentFile.content.days.length-1]) ? this.getWeekOfDate(documentFile.content.days[0]) : ''+this.getWeekOfDate(documentFile.content.days[0]) + ' - ' + this.getWeekOfDate(documentFile.content.days[documentFile.content.days.length-1]);
        const year = this.getYearOfDate(documentFile.content.days[0]) === this.getYearOfDate(documentFile.content.days[documentFile.content.days.length-1]) ? this.getYearOfDate(documentFile.content.days[0]) : ''+this.getYearOfDate(documentFile.content.days[0]) + ' - ' + this.getYearOfDate(documentFile.content.days[documentFile.content.days.length-1]);
        const {lang, showKm} = this.props;
        const {langinterface} = lang;
        const translation = {
          workerName: chooseLanguage('workerName', langinterface),
          project: chooseLanguage('project', langinterface),
          year: chooseLanguage('year', langinterface),
          week: chooseLanguage('week', langinterface),
          day: chooseLanguage('day', langinterface),
          in: chooseLanguage('in', langinterface),
          out: chooseLanguage('out', langinterface),
          break: chooseLanguage('break', langinterface),
          description: chooseLanguage('description', langinterface),
          timer: chooseLanguage('timer', langinterface),
          km: chooseLanguage('km', langinterface),
          extra: chooseLanguage('extra', langinterface),
        }
        const timelistPreview = 
        <> 
           <div id='timelist-data-preview' >
              <div>{translation.workerName}</div>
              <div>{translation.project}</div>
              <div>{translation.week}</div>
              <div>{translation.year}</div>
            </div>
            <div id='timelist-data-preview' >
              <div>{documentFile.content.username}</div>
              <div>{documentFile.content.project}</div>
              <div>{week}</div>
              <div>{year}</div>
            </div>
            <div id={showKm ? 'timelist-content-with-km-preview-header' :'timelist-content-mini-preview-header'} >
              <div>{translation.day}</div>
              <div>{translation.in + ' - ' + translation.out}</div>
              <div>{translation.break}</div>
              <div>{translation.timer}</div>
              {showKm && <div>{translation.km}</div>}
              <div>{translation.description}</div>
            </div>
          {documentFile.content.days.map((day, index)=>(
            <div id={showKm ? 'timelist-content-with-km-preview' : 'timelist-content-mini-preview'} style={{borderColor: documentFile.content.extra && documentFile.content.extra[index] ? "#F00" : "#000"}} key={index}>
              <div>{day.slice(0,5)}</div>
              <div>{documentFile.content.in[index]+' - '+documentFile.content.out[index]}</div>
              <div>{this.breaktimeToString(documentFile.content.breaktime[index])}</div>
              <div>{this.sumUpDay(documentFile.content.days[index] ,documentFile.content.in[index], documentFile.content.out[index], documentFile.content.breakpaid[index], documentFile.content.breaktime[index])}</div>
              {showKm && <div>{documentFile.content.km ? documentFile.content.km[index] : ''}</div>}
              <div>{documentFile.content.daydescription[index]}</div>
            </div>
          ))}
              <div id='timelist-sumup-mini'>
              <div>{translation.timer + ': '}</div>
              <div>{''+sumUp.regular.hours+ ':'+sumUp.regular.minutes}</div>
              {showKm && <div>{translation.km + ': '}</div>}
              {showKm && <div>{''+sumUp.regular.km}</div>}
              <div>{translation.extra + ' ' + translation.timer +': '}</div>
              <div>{''+sumUp.extra.hours+ ':'+sumUp.extra.minutes}</div>
              {showKm && <div>{translation.extra + ' ' + translation.km +': '}</div>}
              {showKm && <div>{''+sumUp.extra.km}</div>}
            </div>
        </>;
        return timelistPreview;
    }

  hovered = () => {
    let {exportOptions} = this.state;
    exportOptions = true;
    this.setState({exportOptions});
  }

  unhovered = () => {
    let {exportOptions} = this.state;
    exportOptions = false;
    this.setState({exportOptions});
  }

  DatesDifference = (dateToCompare) => {
    const date1 = new Date();
    const date2 = new Date(dateToCompare);
    //console.log(date1);
    //console.log(date2);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    return diffDays;
  }

  render (){
    const documentFile = this.props.document ? this.props.document : {};
    const {sendFormToExecute, lang} = this.props;
    return (
      <div id='document-preview' 
      onMouseEnter={this.hovered}
      onMouseLeave={this.unhovered}
      style={{
        width: '100%',
        left: '0px',
        top: '0px',
        position: 'relative'}} 
      pid={documentFile.pid} 
      did={documentFile.did} 
      week={this.props.weekNo} 
      year={this.getYear(documentFile.createdate)} >
      <div id={documentFile.did} style={{zIndex: 9, width: '100%', height: '100%', position: 'absolute', top: 0, left: 0}} onClick={this.fullPreview}></div>
          <div id='document-file' className={documentFile && Object.keys(documentFile).length ? 'show-document' : 'hide-document'}>
          {
             documentFile && Object.keys(documentFile).length && this.previewTimelist(documentFile)
          }
          </div>  
        </div>  
    );
  }
}

export default TimelistPreview;

