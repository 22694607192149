import React, { Component } from "react";
import { chooseLanguage } from "../Lang/langpack";


class DocumentItem extends Component {
  constructor () {
	  super();
	  this.state = {
      hoverItem: false
    }
    this.hoverDocument = this.hoverDocument.bind(this);
    this.unhoverDocument = this.unhoverDocument.bind(this);
    this.fullPreview = this.fullPreview.bind(this);
  }

  hoverDocument(){
    this.setState({
      hoverItem: true
    })
  }
  unhoverDocument(){
    this.setState({
      hoverItem: false
    })
  }

  verifyDocumentTypeAndIco(doc){
    let type = doc.type;
    switch (type) {
      case 'timelist':
        return './timelist.svg';
      break;
      case 'faktura':
        return './faktura.svg';
      break;
      case 'tilbud':
        return './tilbud.svg';
      break;
      default:
        return './documents.svg';
    } 
  }
 
  verifyHeaderType(documentFile){
    let type = documentFile.type;
    const {langinterface} = this.props.lang;
    const docType = chooseLanguage('docType', langinterface);
    switch (type) {
      case 'timelist':
        return docType[2] + ' '+ documentFile.content.timelistnr;
      break;
      case 'faktura':
        return docType[1] + ' '+ documentFile.content.fakturanr;
       break;
      case 'tilbud':
        return docType[0] + ' '+ documentFile.content.tilbudnr;
      break;
      default:
      return 'Jobbleder'
  }
}

    fullPreview(event){
      let did = event.target.getAttribute('name');
      //console.log(did);
      this.props.openFullPreview(did);
    }
  

  componentDidMount() {

  }

  render (){
    const document = this.props.document ? this.props.document : {};
    return (
      <div id='document-list-item'  onMouseEnter={this.hoverDocument} onMouseLeave={this.unhoverDocument}>
        <div className="clickable-panel" name={document.did} onClick={this.fullPreview}/>
          <div  >{this.verifyHeaderType(document)}</div>
          {<div  className="document-settings">
                   <img src={this.verifyDocumentTypeAndIco(document)} 
                    id='doc' 
                    alt='doc'
                    style={{width: '2rem', display: 'inline', marginLeft: '1.3vw', cursor: 'pointer'}}/>                             
                </div>}
        </div>  
    );
  }
}

export default DocumentItem;
