import React, { Component } from "react";
import { Row, Col, Form, Button} from 'react-bootstrap';
import { chooseLanguage } from "../Lang/langpack";


class RegisterNewUserPanel extends Component {
  constructor () {
	  super();
	  this.state = {
    }
    this.saveBasis = this.saveBasis.bind(this);
  }

  componentDidMount() {
  }

  saveBasis(event){
    let record = event.target.id,
      value = event.target.value;
      this.props.writeToState(record, value);
  }


  

  render (){
    const {closeRegister, showPopup, accepterRules} = this.props;
    const {langinterface}= this.props.lang;
    const translation = {
      email: chooseLanguage('email', langinterface),
      username: chooseLanguage('username', langinterface),
      password: chooseLanguage('password', langinterface),
      repassword: chooseLanguage('repassword', langinterface),
      newUser:  chooseLanguage('newUser', langinterface),
      acceptRegistrationRules: chooseLanguage('acceptRegistrationRules', langinterface),
    }
    return (
    <Form>
      <Form.Row className='text-right'>
        <Form.Label className="text-center d-lg-block" style={{color: this.props.error ? '#FF0000' : 'inherit', fontWeight: this.props.error ? 800 : 'initial'}} column lg={3} xs={12}><h4>{translation.username}</h4></Form.Label>
        <Col lg={9} xs={12}>
          <Form.Control id='username' type="text" placeholder={translation.username} onChange={this.saveBasis} required/>
        </Col>
        <Form.Label className="text-center d-lg-block" style={{color: this.props.error ? '#FF0000' : 'inherit', fontWeight: this.props.error ? 800 : 'initial'}} column lg={3} xs={12}><h4>{translation.email}</h4></Form.Label>
        <Col lg={9} xs={12}>
          <Form.Control id='usermail' type="text" placeholder={translation.email} onChange={this.saveBasis} required/>
        </Col>
        <Form.Label className="text-center d-lg-block" style={{color: this.props.error ? '#FF0000' : 'inherit', fontWeight: this.props.error ? 800 : 'initial'}} column lg={3} xs={12}><h4>{translation.password}</h4></Form.Label>
        <Col lg={9} xs={12}>
          <Form.Control id='userpass' type="password" placeholder={translation.password} onChange={this.saveBasis} required/>
        </Col>
        <Form.Label className="text-center d-lg-block" style={{color: this.props.error ? '#FF0000' : 'inherit', fontWeight: this.props.error ? 800 : 'initial'}} column lg={3} xs={12}><h4>{translation.repassword}</h4></Form.Label>
        <Col lg={9} xs={12}>
          <Form.Control id='passrepeat' type="password" placeholder={translation.repassword} onChange={this.saveBasis} required/>
        </Col>
        <Col lg={3} xs={12}>
          <Form.Control id='acceptRules' type="checkbox" onChange={this.saveBasis} required/>
        </Col>    
        <Col lg={9} xs={12}>
          <Form.Label style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => showPopup('userAgreement')}>{translation.acceptRegistrationRules}</Form.Label>
        </Col>   
        <Col>
          <Button id='login' as={Col} onClick={this.props.registerUser} variant='light'>{translation.newUser}</Button>
          <Button id='register' as={Col} onClick={closeRegister} variant='dark'>Cancel</Button>
        </Col>
      </Form.Row>
    </Form>
    );
  }
}

export default RegisterNewUserPanel;
