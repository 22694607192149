const serverInUse = 'global'; //global or local

const pathGenerator = function(connectionOptions){
  const {record, purpose, link, serverType} = {...connectionOptions};
  const linkPathStringify = function(record, purpose, link){
    const paths = {
      api: ['api', 'jobbleder'],
      clients: {
        create: ['user', 'clients', 'create'],
        settings: ['user', 'clients', 'settings'],
      },
      user: {
        login: ['user', 'login'],
        register: ['user', 'register'],
        passreset: ['user', 'password', 'reset'],
        passchange: ['user', 'password', 'change'],
        emailchange: ['user', 'email', 'change'],
        logout: ['user', 'logout'],
        relogin: ['user', 'relogin'],
        dd: ['user', 'dd'],
      },
      projects: {
        create: ['user', 'projects', 'create'],
        download: ['user', 'projects', 'download'],
        settings: ['user', 'projects', 'settings'],
      },
      settings: {
        settings: ['settings'],
        account: ['settings', 'account'],
      },
      documents: {
        addday: ['user', 'documents', 'addday'],
        create: ['user', 'documents', 'create'],
        delete: ['user', 'documents', 'delete'],
        archive: ['user', 'documents', 'archive'],
        download: ['user', 'documents', 'download'],
        generate: ['user', 'documents', 'generate'],
        kreditnota: ['user', 'documents', 'kreditnota'],
        save: ['user', 'documents', 'save'],
        sharelink: ['user', 'documents', 'sharelink'],
        varsel: ['user', 'documents', 'varsel'],
      },
    }
    let pathLink = '/';
    let selectedRecord = paths[record] ? paths[record] : false;
    let selectedPath = selectedRecord && selectedRecord[purpose] ? selectedRecord[purpose] : false;
    if (selectedRecord || selectedPath)
    {
      let apiLink = paths['api'];
      apiLink.map((apiPath)=>(
        pathLink = pathLink + apiPath + '/'
      ));
      selectedPath.map((path)=>(
        pathLink = pathLink + path + '/'
      ));
      pathLink = link ? pathLink + link : pathLink;
      return pathLink;
    }
    else
    {
      return false;
    }
  }
  const serverPathStringify = function(serverType){
    const serverLink = {
      global: {
        protocol: 'https',
        domain: 'laskowskicreative.no',
        port: '',
      },
      local: {
        protocol: 'http',
        domain: '127.0.0.1',
        port: '3003',
      },
    };
    const port = serverLink[serverType].port === '' ? '' : ':'+serverLink[serverType].port;
    const domain = serverLink[serverType].domain;
    const protocol = serverLink[serverType].protocol;
    const addressLink = ''+protocol+'://'+ domain + port;
    return addressLink;
  }
  if (!record || !purpose || !serverType)
  {
    return false;
  } 
  if (record && purpose && serverType)
  {
    const serverPath = serverPathStringify(serverType);
    const linkPath = linkPathStringify(record, purpose, link);
    const resultLink = serverPath && linkPath ? ''+serverPath + linkPath : false;
    return resultLink;
  }
}

const responseBlob = async function (serverLink, requestObject, dataToSend, callback) {
  await fetch(serverLink, requestObject)
  .then(res => res.blob())
  .then(response => {
    //Create a Blob from the PDF Stream
    //console.log(response);
    const file = new Blob([response], {
      type: "application/pdf"
    });
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);
    //Open the URL on new Window
    window.open(fileURL);
    //some return ?
    callback({success: true, task: 'generate pdf file'}, dataToSend);
  })
  .catch(error => {
    //save to log in server
    callback ({success: false, task: 'generate pdf file', error: 'Some internal error. Please try again'})
  })
}

const responseJson = async function (serverLink, requestObject, callback) {
  await fetch(serverLink, requestObject)
  .then((res) => res.json())       
  .then((json) => {
    let {success, task} = json;
    //console.log('success: '+ task);
    if (success)
    {
      
      //console.log(json);
      callback(json, requestObject.body);
    }
    if (!success)
    {
      callback({error: task});
    }
  })
  .catch(error => {
    //save to log in server
    //console.log(error);
    callback ({error: 'Some internal error.'})
  })
}

const requestObjectGenerator = function(method, header){
    const methods = {post: 'POST', get: 'GET'};
    const headers = {
        json: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };
    const requestObject = {
        method: methods[method],
        headers: headers[header]
    }
    return requestObject;
}

const serverConnect = function(options, dataToSend, callback){
  let connectionOptions = options;
  connectionOptions['serverType'] = serverInUse; 
  const serverLink = pathGenerator(connectionOptions);
  if (serverLink && dataToSend)
  {
    let requestObject = requestObjectGenerator('post', 'json');
    requestObject['body'] = JSON.stringify(dataToSend);
    if (connectionOptions.record === 'documents' && (connectionOptions.purpose === 'create' || connectionOptions.purpose === 'generate'))
    {
      responseBlob(serverLink, requestObject, dataToSend, callback);
    }
    else
    {
      responseJson(serverLink, requestObject, callback)
    }
  }
  if (!serverLink)
  {
    callback({success: false, task: 'server connect', error: 'link unreachable'});
  }
  if (!dataToSend)
  {
    callback({success: false, task: 'server connect', error: 'incompatible data format'});
  }
}


export {serverConnect}
