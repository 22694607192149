import { getClientData } from "../DataModelEngine";

const companyName = getClientData('clientName');
const companyEmail = getClientData('clientEmail');

const cookiePolicy = {
    NO: {
        cookiePolicy: [
            {
                header: 'Introduksjon',
                content: [
                    'Denne retningslinjen forklarer hvordan ' + companyName + ' ("Selskapet", "vi", "oss", "vårt") bruker informasjonskapsler og lignende teknologier for å gjenkjenne deg når du besøker vår plattform.',
                    'Her forklarer vi hva disse teknologiene er, hvorfor vi bruker dem, og dine rettigheter vedrørende deres bruk.'
                ],
            },
            {
                header: 'Hva er informasjonskapsler?',
                content: [
                    'Informasjonskapsler er små datafiler som plasseres på enheten din når du besøker et nettsted.',
                    'De hjelper oss med å forbedre brukeropplevelsen, spore analyser og sikre plattformens sikkerhet.'
                ],
            },
            {
                header: 'Typer informasjonskapsler vi bruker',
                content: [
                    'Vi bruker følgende typer informasjonskapsler:',
                    [
                        'Nødvendige informasjonskapsler: Påkrevd for plattformens funksjonalitet (f.eks. brukerautentisering, økthåndtering).',
                        'Analyse-informasjonskapsler: Hjelper oss med å forstå hvordan brukere samhandler med plattformen vår (f.eks. sporing av besøk og bruksstatistikk).'
                    ]
                ],
            },
            {
                header: 'Hvordan vi bruker informasjonskapsler',
                content: [
                    'Vi bruker informasjonskapsler til følgende formål:',
                    [
                        'For å opprettholde brukerøkter og autentisering.',
                        'For å analysere brukeradferd og forbedre våre tjenester.',
                        'For å sikre samsvar med sikkerhetstiltak og forebygging av svindel.'
                    ]
                ],
            },
            {
                header: 'Ditt samtykke og administrasjon av informasjonskapsler',
                content: [
                    'Ved ditt første besøk vil du bli bedt om å gi samtykke til bruk av ikke-essensielle informasjonskapsler.',
                    'Du kan administrere dine innstillinger for informasjonskapsler via nettleserens innstillinger eller vår dedikerte side for administrasjon av informasjonskapsler.',
                    'Du kan når som helst trekke tilbake ditt samtykke uten at det påvirker essensielle funksjoner.'
                ],
            },
            {
                header: 'Informasjonskapsler fra tredjeparter',
                content: [
                    'For øyeblikket bruker vi ikke tredjeparts informasjonskapsler, men hvis dette endres, vil denne retningslinjen bli oppdatert tilsvarende.'
                ],
            },
            {
                header: 'Overholdelse av GDPR og norsk lovgivning',
                content: [
                    'Vi behandler informasjonskapsler i samsvar med EUs personvernforordning (GDPR) og norsk personvernlovgivning.',
                    'Brukere har rett til å be om detaljer om lagrede informasjonskapsler og hvordan de behandles.'
                ],
            },
            {
                header: 'Endringer i denne policyen',
                content: [
                    'Vi kan oppdatere disse retningslinjene fra tid til annen.',
                    'Vesentlige endringer vil bli kommunisert til brukerne før de trer i kraft.'
                ],
            },
            {
                header: 'Kontaktinformasjon',
                content: [
                    'Hvis du har spørsmål om disse retningslinjene, vennligst kontakt oss på: ' + companyEmail + ' .'
                ],
            }
        ]
    },
    EN: {
        cookiePolicy: [
            {
                header: 'Introduction',
                content: [
                    'This policy explains how [Your Company Name] ("Company", "we", "us", "our") uses cookies and similar technologies to recognize you when you visit our platform.',
                    'Here we explain what these technologies are, why we use them, and your rights regarding their use.'
                ],
            },
            {
                header: 'What are cookies?',
                content: [
                    'Cookies are small data files that are placed on your device when you visit a website.',
                    'They help us improve user experience, track analytics, and ensure platform security.'
                ],
            },
            {
                header: 'Types of cookies we use',
                content: [
                    'We use the following types of cookies:',
                    [
                        'Necessary cookies: Required for platform functionality (e.g., user authentication, session management).',
                        'Analytics cookies: Help us understand how users interact with our platform (e.g., tracking visits and usage statistics).'
                    ]
                ],
            },
            {
                header: 'How we use cookies',
                content: [
                    'We use cookies for the following purposes:',
                    [
                        'To maintain user sessions and authentication.',
                        'To analyze user behavior and improve our services.',
                        'To ensure compliance with security measures and fraud prevention.'
                    ]
                ],
            },
            {
                header: 'Your consent and cookie management',
                content: [
                    'Upon your first visit, you will be asked to consent to the use of non-essential cookies.',
                    'You can manage your cookie settings via your browser settings or our dedicated cookie management page.',
                    'You may withdraw your consent at any time without affecting essential functions.'
                ],
            },
            {
                header: 'Third-party cookies',
                content: [
                    'We do not currently use third-party cookies, but if this changes, this policy will be updated accordingly.'
                ],
            },
            {
                header: 'Compliance with GDPR and applicable laws',
                content: [
                    'We process cookies in accordance with the EU General Data Protection Regulation (GDPR) and applicable privacy laws.',
                    'Users have the right to request details about stored cookies and how they are processed.'
                ],
            },
            {
                header: 'Changes to this policy',
                content: [
                    'We may update this policy from time to time.',
                    'Significant changes will be communicated to users before they take effect.'
                ],
            },
            {
                header: 'Contact information',
                content: [
                    'If you have any questions about this policy, please contact us at: [Your contact email].'
                ],
            }
        ]
    },
    PL: {
        cookiePolicy: [
            {
                header: 'Wstęp',
                content: [
                    'Niniejsza polityka wyjaśnia, w jaki sposób [Nazwa Twojej Firmy] ("Firma", "my", "nas", "nasz") wykorzystuje pliki cookie i podobne technologie do rozpoznawania Cię podczas odwiedzania naszej platformy.',
                    'Tutaj wyjaśniamy, czym są te technologie, dlaczego ich używamy oraz jakie masz prawa w związku z ich wykorzystaniem.'
                ],
            },
            {
                header: 'Czym są pliki cookie?',
                content: [
                    'Pliki cookie to małe pliki danych, które są umieszczane na Twoim urządzeniu podczas odwiedzania strony internetowej.',
                    'Pomagają nam poprawić komfort użytkowania, śledzić analizy i zapewniać bezpieczeństwo platformy.'
                ],
            },
            {
                header: 'Rodzaje plików cookie, których używamy',
                content: [
                    'Używamy następujących typów plików cookie:',
                    [
                        'Niezbędne pliki cookie: Wymagane do działania platformy (np. uwierzytelnianie użytkownika, zarządzanie sesjami).',
                        'Analityczne pliki cookie: Pomagają nam zrozumieć, jak użytkownicy wchodzą w interakcję z naszą platformą (np. śledzenie wizyt i statystyk użytkowania).'
                    ]
                ],
            },
            {
                header: 'Jak wykorzystujemy pliki cookie',
                content: [
                    'Pliki cookie wykorzystujemy do następujących celów:',
                    [
                        'Aby utrzymać sesje użytkowników i uwierzytelnianie.',
                        'Aby analizować zachowania użytkowników i ulepszać nasze usługi.',
                        'Aby zapewnić zgodność ze środkami bezpieczeństwa i zapobiegać oszustwom.'
                    ]
                ],
            },
            {
                header: 'Twoja zgoda i zarządzanie plikami cookie',
                content: [
                    'Podczas pierwszej wizyty zostaniesz poproszony o wyrażenie zgody na użycie plików cookie, które nie są niezbędne.',
                    'Możesz zarządzać swoimi ustawieniami plików cookie za pomocą ustawień przeglądarki lub dedykowanej strony do zarządzania plikami cookie.',
                    'Możesz w dowolnym momencie wycofać swoją zgodę, bez wpływu na podstawowe funkcje.'
                ],
            },
            {
                header: 'Pliki cookie stron trzecich',
                content: [
                    'Obecnie nie używamy plików cookie stron trzecich, ale jeśli to się zmieni, niniejsza polityka zostanie odpowiednio zaktualizowana.'
                ],
            },
            {
                header: 'Zgodność z RODO i obowiązującymi przepisami',
                content: [
                    'Przetwarzamy pliki cookie zgodnie z ogólnym rozporządzeniem o ochronie danych (RODO) i obowiązującymi przepisami o ochronie prywatności.',
                    'Użytkownicy mają prawo do uzyskania informacji na temat przechowywanych plików cookie i sposobu ich przetwarzania.'
                ],
            },
            {
                header: 'Zmiany w niniejszej polityce',
                content: [
                    'Możemy od czasu do czasu aktualizować niniejszą politykę.',
                    'Istotne zmiany zostaną zakomunikowane użytkownikom przed ich wejściem w życie.'
                ],
            },
            {
                header: 'Informacje kontaktowe',
                content: [
                    'Jeśli masz jakiekolwiek pytania dotyczące niniejszej polityki, skontaktuj się z nami pod adresem: [Twój adres e-mail kontaktowy].'
                ],
            }
        ]
    }
};

const returnPrivacyDocument = (record, lang) =>
{
	let outputObject = {};
	for (let [collectionName, languagesCollections] of Object.entries(cookiePolicy)) {
		outputObject[collectionName] = languagesCollections[record];
	}
    
	return outputObject[lang];
}

export {returnPrivacyDocument};