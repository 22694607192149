import React, { Component } from "react";
import AddressComponent from "../FormComponents/AddressComponent";

import './../settings.css';
import TextComponent from "../FormComponents/TextComponent";
import TimeComponent from "../FormComponents/TimeComponent";
import DescriptionComponent from "../FormComponents/DescriptionComponent";
import BreakValueComponent from "../FormComponents/BreakValueComponent";
import Vat from "../FormComponents/Vat";
import NumberComponent from "../FormComponents/NumberComponent";
import ToggleComponent from "../FormComponents/ToggleComponent";
import RangeComponent from "../FormComponents/RangeComponent";
import MVAComponent from "../FormComponents/MVAComponent";
import KontoComponent from "../FormComponents/KontoComponent";
import LangComponent from "../FormComponents/LangComponent";
import PostNrComponent from "../FormComponents/PostNrComponent";
import { postkode } from "../Documents/postkode";
import { chooseLanguage } from "../Lang/langpack";
import PasswordChangeComponent from "../FormComponents/PasswordChangeComponent";
import EmailChangeComponent from "../FormComponents/EmailChangeComponent";
import CraftComponent from "../FormComponents/CraftComponent";
import ImageComponent from "../FormComponents/ImageComponent";


class EditSetting extends Component {
  constructor () {
	  super();
	  this.state = {
      type: false,
      option: false,
      value: false,
      pid: false,
    }
  }
  componentDidMount() {
    const {type, option, value, pid} = {...this.props.editSetting.setting};
    this.setState({type, option, value, pid});
  }


  closeMenu = ()=>
  {
    const {close} = {...this.props.editSetting};
  }


    savePost = () => 
  {
      const postnr = this.state.value;
      let stringPostNr = ''+postnr;
      //console.log(stringPostNr);
      let selectedCity = stringPostNr.length === 4 && postkode.filter((city) => city[0] === stringPostNr);
      //console.log(selectedCity);
      selectedCity = selectedCity[0] ? selectedCity[0][1] : null;
    const {modifySetting}={...this.props.editSetting};
      //odczytaj z state i zapisz do bazy danych
        modifySetting('companypost', selectedCity);
        modifySetting('companypostnr', stringPostNr);
    }

  saveSettingToState = (option, pid, value) =>{
    //zapisz do state
    this.setState({value});
  }

  modifyProjectSetting = () =>
  {
    let {option, value, pid, type} = {...this.state};
    //console.log("before: " +value);
    value = type === 'number' ? +value : value;
    //console.log("after: " +value);
    const {modifyProjectSetting}={...this.props.editSetting};
    //odczytaj z state i zapisz do bazy danych
    modifyProjectSetting(pid, option, value);
  }

  modifySetting = () =>
  {
    let {option, value, type} = {...this.state};
    //console.log("before: " +value);
    value = type === 'number' ? +value : value;
    value = type === 'konto' ?  value.slice(0,4) + '.' + value.slice(4,6) + '.' + value.slice(-5) : value;
    //console.log("after: " +value);
    const {modifySetting}={...this.props.editSetting};
    //odczytaj z state i zapisz do bazy danych
    modifySetting(option, value);
  }

  render (){
    const backgroundImage = 'edit.svg';
    const {close} ={...this.props.editSetting};
    const {setting} = this.props.editSetting;
    const {passwordChange, emailChange}= this.props.editSetting;
    const {type, option, value, pid} = {...this.state};
    const {lang} = this.props;
    const {langinterface} = lang;
    const translation = {
      editSetting: chooseLanguage('editSetting', langinterface)
    }
      return (  
      <div className="edit-setting-background">
        <div className="edit-setting-window">
          <div className='popup-topbeam' style={{backgroundImage: 'url(./'+backgroundImage}}><h1>{translation.editSetting + ': ' + option}</h1>
          {<div className='popup-close-button' onClick={close}>X</div>}</div>
          <div className="edit-setting-window-box">
            {(type === 'postnr') && <PostNrComponent
            lang={lang} 
            record={option} postnr={value}
            savePost={this.saveSettingToState}
            />} 
            {type === 'text' && <TextComponent record={option} value={value} index={pid} saveValue={this.saveSettingToState}/>}
            {type === 'konto' && <KontoComponent record={option} value={value} index={pid} saveValue={this.saveSettingToState}/>}
            {type === 'time' && 
            <div className="popup-time-component">
              <TimeComponent index={pid} record={option} time={value} saveTime={this.saveSettingToState}/>
            </div>
            }
            {type === 'mva' &&
            <div className="popup-time-component">
              <MVAComponent index={pid} record={option} value={value} saveValue={this.saveSettingToState}/>
            </div>
            }
            {type === 'logo' &&
            <div className="popup-time-component">
              <ImageComponent record={option} value={value} saveValue={this.saveSettingToState}/>
            </div>
            }
            {(type === 'number' || type === 'limit') &&
            <div className="popup-time-component">
              <NumberComponent index={pid} record={option} value={value} saveValue={this.saveSettingToState}/>
            </div>
            }
            {(type === 'range') &&
            <div className="popup-time-component">
              <RangeComponent index={pid} record={option} value={value} saveValue={this.saveSettingToState}/>
            </div>
            }
            {type === 'description' && 
            <div className="popup-time-component">
              <DescriptionComponent index={pid} record={option} value={value} saveValue={this.saveSettingToState}/>
            </div>
            }
            {type === 'vat' && 
            <div className="popup-time-component">
              <Vat index={pid} record={option} vat={value} saveValue={this.saveSettingToState}/>
            </div>
            }
            {type === 'breaktime' && 
            <div className="popup-time-component">
              <BreakValueComponent index={pid} record={option} breakValue={value} saveValue={this.saveSettingToState}/>
            </div>
            }
            {type === 'lang' && 
            <div className="popup-time-component">
              <LangComponent index={pid} record={option} value={value} saveValue={this.saveSettingToState}/>
            </div>
            }
            {type === 'toggle' && 
            <div className="popup-time-component">
              <ToggleComponent index={pid} record={option} value={value} saveValue={this.saveSettingToState}/>
            </div>
            }
            {type === 'password' && 
            <div className="popup-time-component">
              <PasswordChangeComponent closePopup={close} lang={lang} index={pid} record={option} value={value} passwordChange={passwordChange}/>
            </div>
            }
            {type === 'email' && 
            <div className="popup-time-component">
              <EmailChangeComponent closePopup={close} lang={lang} index={pid} record={option} value={value} emailChange={emailChange}/>
            </div>
            }
            {type === 'craft' && 
            <div className="popup-time-component">
              <CraftComponent closePopup={close} lang={lang} index={pid} record={option} value={value} saveValue={this.saveSettingToState}/>
            </div>
            }
          </div>
          {setting.value !== value && 
          <div className="popup-buttons">
            <img src='./ok.svg' 
              id='accept'  
              onClick={pid ? this.modifyProjectSetting : type === 'postnr' ? this.savePost : this.modifySetting}
              alt='accept'
              style={{width: '5rem', display: 'inline', marginLeft: '1.3vw', marginTop: '2vh', cursor: 'pointer'}}/>
            <img src='./abort.svg' 
              id='abort'  
              onClick={close}
              alt='abort'
              style={{width: '5rem', display: 'inline', marginLeft: '1.3vw', marginTop: '2vh', cursor: 'pointer'}}/>
          </div>   
          }
        </div>
      </div>
    );
  }
}

export default EditSetting;
