import React, { Component } from "react";


class ProjectItem extends Component {
  constructor () {
	  super();
	  this.state = {
      hoverItem: false
    }
    this.hoverProject = this.hoverProject.bind(this);
    this.unhoverProject = this.unhoverProject.bind(this);
  }

  hoverProject(){
    this.setState({
      hoverItem: true
    })
  }
  unhoverProject(){
    this.setState({
      hoverItem: false
    })
  }
 
  openProject = (e) =>
  {
    let id = e.target.id;
    //console.log(id);
    const open = this.props.projectController.open; 
    open(id);
    const {clearList} = {...this.props};
    clearList();
  }

  componentDidMount() {

  }

  render (){
    const {project, index} = {...this.props};
    return (
      <div id='project-list-item' onMouseEnter={this.hoverProject} onMouseLeave={this.unhoverProject}>
      <div className="clickable-panel"
        id={project.pid} 
        onClick={this.openProject}></div>
          <div>{project.name}</div>
          {this.state.hoverItem && <div className="project-settings">
                   <img src='./projects_full.svg' 
                    alt='abort'
                    style={{
                      width: '2rem', 
                      display: 'inline', 
                      marginLeft: '1.3vw', 
                      cursor: 'pointer'}}/>                             
                </div>}
        </div>  
    );
  }
}

export default ProjectItem;
