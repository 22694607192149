import React, { Component } from "react";
import {Col, Row} from 'react-bootstrap';
import $ from 'jquery';

import DocumentPreview from "./DocumentPreview";
import TimelineDocIco from "./TimelineDocIco";
import TimelineNewDocIco from "./TimelineNewDocIco";
import TimelineDocRow from "./TimelineDocRow";

class TimelineDayContainer extends Component {
  constructor () {
	  super();
	  this.state = {
      step: 0,
      previewFile: false,
    }
}


  isSunday = (day) => {
    let dte= new Date(day.slice(6,10), +day.slice(3,5)-1, day.slice(0,2));
    let weekDay = dte.getDay();
    const result = weekDay === 0 ? true : false;
    return result;
  }



  
  isToday = (day) => {
    let dateToEqual = new Date(day.slice(6,10), +day.slice(3,5)-1, day.slice(0,2));
    let day1 = dateToEqual.getDate();
    day1 = ''+day1;
    day1 = day1.length < 2 ? '0'+day1 : day1;
    let month1 = +dateToEqual.getMonth();
    month1 = month1 + 1;
    month1 = ''+month1;
    month1 = month1.length < 2 ? '0'+month1 : month1;
    let year1 = dateToEqual.getFullYear();

    let dateOfDocument = new Date ();
    let day2 = dateOfDocument.getDate();
    day2 = ''+day2;
    day2 = day2.length < 2 ? '0'+day2 : day2;
    let month2 = +dateOfDocument.getMonth();
    month2 = month2 + 1;
    month2 = ''+month2;
    month2 = month2.length < 2 ? '0'+month2 : month2;
    let year2 = dateOfDocument.getFullYear();

    const result = day1 === day2 && month1 === month2 && year1 === year2 ? true : false;
    return result;
  }

  dateComparsion(dateToComapare, timelineDate)
  {
    let dateToEqual = new Date(timelineDate.slice(6,10), +timelineDate.slice(3,5)-1, timelineDate.slice(0,2));
    let day1 = dateToEqual.getDate();
    day1 = ''+day1;
    day1 = day1.length < 2 ? '0'+day1 : day1;
    let month1 = +dateToEqual.getMonth();
    month1 = month1 + 1;
    month1 = ''+month1;
    month1 = month1.length < 2 ? '0'+month1 : month1;
    let year1 = dateToEqual.getFullYear();

    if (typeof dateToComapare === 'object')
    {
      //console.log('Object: ' + dateToComapare)
      for(let i = dateToComapare.length-1; i>=0; i--)
      {
        let tempDoc = dateToComapare[i];
        //console.log(dateToComapare[i]);
        let dateOfDocument = new Date(tempDoc.slice(6,10), +tempDoc.slice(3,5)-1, tempDoc.slice(0,2));
        let day2 = dateOfDocument.getDate();
        day2 = ''+day2;
        day2 = day2.length < 2 ? '0'+day2 : day2;
        let month2 = +dateOfDocument.getMonth();
        month2 = month2 + 1;
        month2 = ''+month2;
        month2 = month2.length < 2 ? '0'+month2 : month2;
        let year2 = dateOfDocument.getFullYear();
        const result = day1 === day2 && month1 === month2 && year1 === year2 ? true : false;
        if(result === true || i === 0)
        {
          //console.log(day1 + ' | ' + day2+ ' / ' + month1 + ' | ' + month2 + ' / ' + year1  + ' | ' + year2+ ' = ' + result);
          return result;
        }    
      }     
    }
    else
    {   
        let dateOfDocument = new Date (dateToComapare);
        let day2 = dateOfDocument.getDate();
        day2 = ''+day2;
        day2 = day2.length < 2 ? '0'+day2 : day2;
        let month2 = +dateOfDocument.getMonth();
        month2 = month2 + 1;
        month2 = ''+month2;
        month2 = month2.length < 2 ? '0'+month2 : month2;
        let year2 = dateOfDocument.getFullYear();
        const result = day1 === day2 && month1 === month2 && year1 === year2 ? true : false;
        //console.log(day1 + ' | ' + day2+ ' / ' + month1 + ' | ' + month2 + ' / ' + year1  + ' | ' + year2+ ' = ' + result);
        return result;

    }
  
  }


  render (){
    let {weekNo, docs, day, fullPreview, previewFile, index, view, lang} = this.props;
    let isHidden = [];
    isHidden = docs && docs.map((doc,idx)=>(this.dateComparsion(doc.createdate, day) ? true : false));
    isHidden = (view==='calendar' || (isHidden && isHidden.includes(true))) ? true : false;
    return (  
      <>        
        {( isHidden ) && <div 
        id='timeline-day-mobile' 
        style={{
            color: this.isSunday(day) ? '#FF0000' : '#000', 
            fontWeight: this.isToday(day) ? '700' : '400'
        }}>
            {view === 'calendar' && <div 
            id='week-no-mobile' 
            style={{
                borderTop: view === 'calendar' && (this.isSunday(day) || index===0 )  ? '#666 1px solid' : 'none'
            }}>
                {
                  this.isSunday(day) || index===0 || (isHidden && view !=='calendar') ? <p id='week-number-sunday'>{weekNo}</p> : <p id='week-number'>{weekNo}</p>
                }
            </div>}
            <div id='timeline-date'
                        style={{
                borderTop: view === 'calendar' && (this.isSunday(day) || index===0 )  ? '#666 1px solid' : 'none'
            }}>{day}</div>
            <div id='timeline-documents-mobile'>
                {
                  view==='calendar' && docs && docs.map((doc,idx)=>(this.dateComparsion(doc.createdate, day) ? <TimelineDocIco lang={lang} fullPreview={fullPreview} doc={doc} idx={idx} key={idx} weekNo={weekNo} previewFile={previewFile} /> : ''))
                }
                {
                  view==='timeline' && docs && docs.map((doc,idx)=>(this.dateComparsion(doc.createdate, day) ? <TimelineDocRow lang={lang} fullPreview={fullPreview} doc={doc} idx={idx} key={idx} weekNo={weekNo} previewFile={previewFile} /> : ''))
                }
                {
                  //view === 'calendar' && <TimelineNewDocIco/>
                }
            </div>                    
        </div>}
        {
          !isHidden && <div >
          </div>
        }
      </>
    );
  }
}

export default TimelineDayContainer;
