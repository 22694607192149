import React, { Component } from "react";
import PopupAd from "./PopupAd";
import PopupConfirm from "./PopupConfirm";
import PopupEdit from "./PopupEdit";
import PopupError from "./PopupError";
import PopupWarning from "./PopupWarning";
import PopupInfo from "./PopupInfo";
import PopupNewProject from "./PopupNewProject";
import PopupNewUser from "./PopupNewUser";
import PopupNewTask from "./PopupNewTask";
import PopupNewClient from "./PopupNewClient";
//import { Row, Col, Form} from 'react-bootstrap';

import './../popup.css';
import { chooseLanguage } from "../Lang/langpack";
import PopupNewDay from "./PopupNewDay";
import PopupUserAgreement from "./PopupUserAgreement";
import PopupCookiesPolicy from "./PopupCookiesPolicy";

class PopupWindow extends Component {
  constructor () {
	  super();
	  this.state = {
    }
    this.setHidden = this.setHidden.bind(this);
    this.setVisible = this.setVisible.bind(this);
  }

  componentDidMount() {
    this.setHidden();
  }
  componentWillUnmount() {
    this.setVisible();
  }
  
setHidden () {
      document.body.style.overflow = "hidden";
}
setVisible () {
  document.body.style.overflow = "auto";
}

  render (){
/*
new user
new project
confirm
edit
error
info
settings
ad
*/
const {type, content, callback, abort} = {...this.props.popup}
let showCloseButton = type === 'newUser' || type === 'newProject' || type==='newTask' || type==='newClient' || type === 'edit' || type === 'settings' || type === 'ad' ?  true : false;
let backgroundImage = type === 'newProject' ? 'new_project.svg' : type === 'newDay' ? 'time_plus.svg' : type === 'newTask' ? 'new_task.svg' : type === 'newUser' ? 'new_user.svg' : type === 'newClient' ? 'new_client.svg' : type === 'settings' ? '' : type === 'error' ? 'popup_error.svg' : type === 'warning' ? 'popup_warning.svg' : type === 'info' ? 'popup_information.svg' : type === 'confirm' ? 'popup_confirm.svg' : '';
const {lang, saveDay, timelistdetails, addDay} = {...this.props};
let {langinterface} = lang;
langinterface = langinterface ? langinterface : Navigator.language === "pl-PL" ? 'PL' : Navigator.language === "no-NO" ? 'NO' : 'EN';
const translation = {
  newProject: chooseLanguage('newProject', langinterface),
  newClient: chooseLanguage('newClient',  langinterface),
  kontonr: chooseLanguage('kontonr',  langinterface),  
  address: chooseLanguage('address',  langinterface),
  mva: chooseLanguage('mva',  langinterface),
  post: chooseLanguage('post',  langinterface), 
  invoiceSettings: chooseLanguage('invoiceSettings',  langinterface),
  newUser: chooseLanguage('newUser', langinterface),
  newDay: chooseLanguage('newDay', langinterface)
}
let title =  type === 'newProject' ? translation.newProject : type === 'newDay' ? translation.newDay : type === 'newTask' ? 'add task' : type === 'newClient' ? translation.newClient : type === 'newUser' ? translation.newUser : type === 'settings' ? 'Settings' : type === 'ad' ? 'advertisement' : type === 'userAgreement' ? 'User Agreement' : type === 'cookiesPolicy' ? 'Cookies Policy' : type;
    return (
      <>
        {type !== 'warning' && type !== 'info' && <div className="popup-background">
            <div className="popup-window">
                <div className='popup-topbeam' style={{backgroundImage: 'url(./'+backgroundImage}}>
                  <h1>{title}</h1>
                  {<div className='popup-close-button' onClick={abort}>X</div>}
                </div>

                {type === 'ad' && <PopupAd content={content} closePopup={abort}/>}
                {type === 'userAgreement' && <PopupUserAgreement content={content} closePopup={abort}/>}
                {type === 'cookiesPolicy' && <PopupCookiesPolicy content={content} closePopup={abort}/>}
                {type === 'confirm' && <PopupConfirm content={content} handleResponse={this.props.handleResponse}  closePopup={abort}/>}
                {type === 'edit' && <PopupEdit content={content} saveEditedValue={this.props.saveEditedValue} closePopup={abort}/>}
                {type === 'error' && <PopupError content={content} handleErrorResponse={this.props.handleErrorResponse}  closePopup={abort}/>}
                {type === 'warning' && <PopupWarning content={content} handleErrorResponse={this.props.handleErrorResponse} closePopup={abort}/>}
                {type === 'info' && <PopupInfo content={content} closePopup={abort}/>}
                {type === 'newProject' && <PopupNewProject lang={lang} createNewProject={this.props.createNewProject} closePopup={abort}/>}
                {type === 'newUser' && <PopupNewUser lang={lang} createNewUser={this.props.createNewUser} closePopup={abort}/>}
                {type === 'newClient' && <PopupNewClient lang={lang} createNewClient={this.props.createNewClient} closePopup={abort}/>}
                {type === 'newTask' && <PopupNewTask lang={lang} createNewTask={this.props.createNewTask} closePopup={abort}/>}
                {type === 'newDay' && <PopupNewDay abort={abort} lang={lang} addDay={addDay} timelistdetails={timelistdetails} saveDay={saveDay}/>}
                {(type !== 'newProject' && type !== 'newTask' && type !== 'newDay') && <div className="popup-buttons">
                  {(type === 'confirm' || type === 'warning' || type === 'info' || type==='newDay' || type === 'userAgreement' || type === 'cookiesPolicy') && <img src='./ok.svg' 
                    id='accept'
                    onClick={callback}
                    alt='accept'
                    style={{width: '5rem', display: 'inline', marginLeft: '1.3vw', marginTop: '2vh',marginBottom: '2vh', cursor: 'pointer'}}/>}
                    
                  {type === 'error' && <img src='./retry.svg' 
                    id='retry'
                    onClick={callback}
                    alt='retry'
                    style={{width: '5rem', display: 'inline', marginLeft: '1.3vw', marginTop: '2vh',marginBottom: '2vh', cursor: 'pointer'}}/>}
                  {(type === 'confirm' || type === 'warning' || type === 'error'|| type==='newDay') && <img src='./abort.svg' 
                    id='abort' 
                    onClick={abort}
                    alt='abort'
                    style={{width: '5rem', display: 'inline', marginLeft: '1.3vw', marginTop: '2vh',marginBottom: '2vh', cursor: 'pointer'}}/>}
                </div>}
            </div>
        </div>}
      </>
    );
  }
}

export default PopupWindow;
