import React, { Component } from "react";

class FrontLoginPage extends Component {
    constructor () {
        super();
        this.state = {
            
        }
    }


    langPack = (lang) => {
        //console.log(lang);
        const translations = {
            PL: <>           
                <p><span>Oprogramowanie biurowe</span> dla Twojej firmy w Norwegii.</p>
                <p>Jobbleder to <span>darmowa aplikacja</span> do tworzenia dokumentów.</p>
                <p>Łatwo uzupełniaj <span>timelistę, zrób wycenę i wystaw fakturę.</span></p>
                <p><span>Intuicyjny kreator</span> przeprowadzi Cię krok po kroku.</p>
                <p><span>Zapisuj dniówki</span> aby później zrobić timelistę z całego tygodnia.</p>
                <p>Jednym kliknięciem zrób <span>fakturę z timelisty</span>.</p>
                <p>Napisz <span>tilbud</span> i <span>utwórz z niego fakturę.</span></p>
                <p>Dokumenty możesz <span>pobrać jako pdf; wysłać lub wydrukować.</span></p>
                <p><span>Zapisz projekt i dane klienta</span> aby łatwo uzupełniać dokumenty.</p>
                <p><span>Utwórz konto</span> i sprawdź jakie to proste.</p>
            </>,
            EN: <>
                <p><span>Office software</span> for your company in Norway.</p>
                <p>Jobbleder is a <span>free application</span> for creating documents.</p>
                <p>Easily fill in <span>timesheet, budget and issue invoices.</span></p>
                <p><span>The intuitive wizard</span> will guide you step by step.</p>
                <p><span>Save daily</span> to later create a timelist for the entire week.</p>
                <p>Create an <span>invoice from the timelist</span> with one click.</p>
                <p>Make a <span>budget</span> and <span>create an invoice from it.</span></p>
                <p>You can <span>download documents as pdf; send or print.</span></p>
                <p><span>Save project and client data</span> to easily manage documents.</p>
                <p><span>Create an account</span> and check how easy it is.</p>
            </>,
            NO: <>
                <p><span>Kontorprogramvare</span> for din bedrift i Norge.</p>
                <p>Jobbleder er et <span>gratis program</span> for å lage dokumenter.</p>
                <p>Fullfør enkelt <span>tidslisten din, kom med et tilbud og utsted en faktura.</span></p>
                <p><span>Intuitiv veiviser</span> vil veilede deg trinn for trinn.</p>
                <p><span>Skriv ned dagene dine</span> for senere å lage en tidsliste for hele uken.</p>
                <p>Opprett en <span>faktura fra tidslisten</span> med ett klikk.</p>
                <p>Skriv <span>tilbud</span> og <span>opprett en faktura fra det.</span></p>
                <p>Du kan <span>laste ned dokumentene som pdf; send eller skriv ut.</span></p>
                <p><span>Lagre prosjekt- og klientdetaljer</span> for å enkelt fullføre dokumenter.</p>
                <p><span>Opprett en konto</span> og se hvor enkelt det er.</p>
            </>

        } 
        //console.log(translations[lang]);
        return translations[lang];
    }

  render (){
    const {lang} = this.props;
    const {langinterface} = lang;
    return (
      <>
          <div className="front-login-page">
            {this.langPack(langinterface)}
          </div>
      </>
    );
  }
}

export default FrontLoginPage;
